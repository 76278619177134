import type { AllowedValueType, FunctionOverloadDef, KnownAstInfo, ParameterDef } from '@thinkalpha/language-services';
import {
    AstNodeType,
    FieldTypeCategory,
    isParameterTyped,
    isSeriesTyped,
    renderSlidingWindow,
    renderTimeframe,
} from '@thinkalpha/language-services';
import type {
    ConcreteIndicator,
    AllowedArgument,
    EnumeratedOption,
    IndicatorImport,
    IndicatorParameter,
    IndicatorRef,
} from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type {
    AllowedArgumentViewModel,
    EnumeratedOptionViewModel,
    IndicatorImportViewModel,
    IndicatorImportRefViewModel,
    IndicatorParameterViewModel,
} from 'src/contracts/dictionary-view-model';
import {
    isEnumeratedOptionAllowedArgument,
    isSlidingWindowAllowedArgument,
    isTimeframeAllowedArgument,
} from 'src/contracts/dictionary-view-model';
import { isPointInTime, isTimeframe } from 'src/contracts/timeframe';
import type {
    DictionaryFunctionDef,
    DictionaryViewModelFunctionDef,
    IndicatorViewModelOrImportViewModel,
} from 'src/lib/dictionaryModel';
import { isIndicatorImport, isIndicatorViewModelImport } from 'src/lib/dictionaryModel';

/**
 * @deprecated
 */
export function isAllowedArgumentViewModelEnumerated(arg: AllowedArgumentViewModel): arg is EnumeratedOptionViewModel {
    if (typeof arg !== 'object') return false;
    if (!arg) return false;
    if ('input' in arg) return true;
    return false;
}

export function isAllowedArgumentEnumerated(arg: AllowedArgument): arg is EnumeratedOption {
    if (typeof arg !== 'object') return false;
    if (!arg) return false;
    if ('input' in arg) return true;
    return false;
}

export function determineDataType(value: AllowedValueType): FieldTypeCategory {
    switch (typeof value) {
        case 'string':
            return FieldTypeCategory.String;
        case 'number':
            return FieldTypeCategory.Double;
        case 'object': {
            // if (value === null) return '';
            if (isTimeframe(value)) return FieldTypeCategory.Timeframe;
            if (isPointInTime(value)) return FieldTypeCategory.PointInTime;
            return FieldTypeCategory.Duration;
        }
    }

    throw new Error(`Cannot determine data type of: ${value}`);
}

export const isFullIndicatorImport = (
    ref: IndicatorImportViewModel | IndicatorImportRefViewModel,
): ref is IndicatorImportViewModel => {
    return (ref as IndicatorImportViewModel).formula !== undefined;
};

export const filterIndicatorImportsOrRefs = (
    refs: (IndicatorImportViewModel | IndicatorImportRefViewModel)[],
): IndicatorImportViewModel[] => {
    return refs.filter((x) => isFullIndicatorImport(x)) as IndicatorImportViewModel[];
};

/**
 * @deprecated
 */
export function mapIndicatorViewModelToDictionaryFunctionDef(
    imp: IndicatorViewModelOrImportViewModel,
): DictionaryViewModelFunctionDef {
    const overloads: FunctionOverloadDef[] = [];
    if (
        !isSeriesTyped(imp.dataType) &&
        !isParameterTyped(imp.dataType) &&
        imp.params?.length === 1 &&
        imp.params?.every((x) => !isSeriesTyped(x.dataType))
    ) {
        overloads.push({
            params:
                imp.params?.map((x) =>
                    mapIndicatorViewModelParamToFunctionDefParam({ ...x, dataType: { series: x.dataType } }),
                ) ?? [],
            returnType: { series: imp.dataType },
            outputUnit: imp.unit ?? undefined,
        });
    }
    // this overload must come second, because of automatic downcasting
    overloads.push({
        params: imp.params?.map((x) => mapIndicatorViewModelParamToFunctionDefParam(x)) ?? [],
        returnType: imp.dataType,
        outputUnit: imp.unit ?? undefined,
    });

    return {
        id: imp.id,
        indicator: imp,
        overloads,
        symbol: isIndicatorViewModelImport(imp) ? imp.alias : imp.symbol,
        alias: isIndicatorViewModelImport(imp) ? imp.alias : undefined,
    };
}

export function mapIndicatorToDictionaryFunctionDef(imp: ConcreteIndicator | IndicatorImport): DictionaryFunctionDef {
    const overloads: FunctionOverloadDef[] = [];
    if (
        !isSeriesTyped(imp.dataType) &&
        !isParameterTyped(imp.dataType) &&
        imp.params?.length === 1 &&
        imp.params?.every((x) => !isSeriesTyped(x.dataType))
    ) {
        overloads.push({
            params:
                imp.params?.map((x) =>
                    mapIndicatorParamToFunctionDefParam({ ...x, dataType: { series: x.dataType } }),
                ) ?? [],
            returnType: { series: imp.dataType },
            outputUnit: imp.unit ?? undefined,
        });
    }
    // this overload must come second, because of automatic downcasting
    overloads.push({
        params: imp.params?.map((x) => mapIndicatorParamToFunctionDefParam(x)) ?? [],
        returnType: imp.dataType,
        outputUnit: imp.unit ?? undefined,
    });

    return {
        id: imp.id,
        indicator: imp,
        overloads,
        symbol: isIndicatorImport(imp) ? imp.alias : imp.symbol,
        alias: isIndicatorImport(imp) ? imp.alias : undefined,
    };
}

/**
 * @deprecated
 */
export function mapIndicatorViewModelParamToFunctionDefParam(param: IndicatorParameterViewModel): ParameterDef {
    return {
        dataType: param.dataType,
        allowedValues: param.options?.values?.map(
            (opt): KnownAstInfo => ({
                type: AstNodeType.const,
                value: isAllowedArgumentViewModelEnumerated(opt) ? opt.input : (opt as any),
                dataType: determineDataType(isAllowedArgumentViewModelEnumerated(opt) ? opt.input : (opt as any)),
            }),
        ),
        unit: param.unit ?? undefined,
        name: param.name,
        optional: param.optional || param.defaultValue !== undefined,
    };
}

export function mapIndicatorParamToFunctionDefParam(param: IndicatorParameter): ParameterDef {
    return {
        dataType: param.dataType,
        allowedValues: param.options?.values?.map(
            (opt): KnownAstInfo => ({
                type: AstNodeType.const,
                value: isAllowedArgumentEnumerated(opt) ? opt.input : (opt as any),
                dataType: determineDataType(isAllowedArgumentEnumerated(opt) ? opt.input : (opt as any)),
            }),
        ),
        unit: param.unit ?? undefined,
        name: param.name,
        optional: param.optional || param.defaultValue !== undefined,
    };
}

// export const mapIndicatorToDictionaryFunctionDef = (x: IndicatorOrImport): DictionaryFunctionDef => ({
//     id: x.id,
//     indicator: x,
//     params: x.params.map(
//         (param): ParameterDef => ({
//             dataType: param.dataType,
//             name: param.name,
//             optional: param.optional || param.defaultValue !== undefined,
//             // todo: these next 2 properties will break if the default value of a param is an indicator ref or expression
//             allowedValues: param.options?.values?.map(
//                 (opt): KnownAstInfo => ({
//                     type: AstNodeType.const,
//                     value: isAllowedArgumentEnumerated(opt) ? opt.input : (opt as any),
//                     dataType: determineDataType(isAllowedArgumentEnumerated(opt) ? opt.input : (opt as any)),
//                 }),
//             ),
//         }),
//     ),
//     returnType: x.dataType,
//     symbol: isIndicatorImport(x) ? x.alias : x.symbol,
//     alias: isIndicatorImport(x) ? x.alias : undefined,
//     outputUnit: x.unit ?? undefined,
// });

export function parseAllowedArgument(argument: AllowedArgumentViewModel): string {
    if (isEnumeratedOptionAllowedArgument(argument)) return `${argument.input}`;
    if (isSlidingWindowAllowedArgument(argument)) return renderSlidingWindow(argument);
    if (isTimeframeAllowedArgument(argument)) return renderTimeframe(argument);
    if (!argument) return '';
    return argument.toString();
}

/**
 * The server often gives us back the full indicator, but when we give this back to the server,
 * it thinks we're giving it a bespoke indicator. Instead, we just want the id and version with
 * no additional properties.
 * @param indicatorRef The indicator, indicator ref, or anything else shaped like an indicator ref
 * @returns A bare indicator ref with no superfluous properties
 */
export function justTheIndicatorRef(indicatorRef: IndicatorRef): IndicatorRef {
    //
    return {
        id: indicatorRef.id,
        version: indicatorRef.version,
    };
}
