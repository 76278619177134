import type { AlphabotConnectionStatus, AlphabotWebsocketMessageBot } from '../../types';
import { MessageDocument } from '../MessageDocument';
import type { ChatClientModel } from './index';
import { Instant } from '@js-joda/core';
import { computed, signal } from '@preact/signals-react';
import type { ReadonlySignal } from '@preact/signals-react';
import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import { container } from 'src/StaticContainer';
import { appConfig } from 'src/lib/config';
import { getChatConversationQuery, makeChatHistoryKey } from 'src/queries/chatBot';
import type { ReactBindings } from 'src/types/bindings';

const log = container.get('Logger').getSubLogger({ name: 'feat:ChatBot:ChatClientModel' });

const CHATBOT_BASE_URL = appConfig.chatbotApi.replace('http', 'ws');

export class ChatClientModelImpl implements ChatClientModel, Disposable {
    #authToken: string | undefined;

    async #authenticate(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.#socket) {
                throw new Error('Not connected to Chatbot server');
            }

            this.#socket.onAny((event, ...args) => {
                switch (event) {
                    case 'auth.success':
                        if (TRACE) {
                            log.trace('Authentication successful');
                        }
                        this.#socket?.offAny();
                        resolve();
                        break;
                    case 'auth.error':
                        log.error('Authentication error:', { args });
                        this.#socket?.offAny();
                        reject('Authentication failed');
                        break;
                    default:
                        log.warn('Unexpected event:', { event, args });
                        break;
                }
            });

            this.#socket.emit('auth', `Bearer ${this.#authToken}`);
        });
    }

    #baseUrl: string = CHATBOT_BASE_URL;

    connect(userId: string | undefined, authToken: string | undefined): Promise<void> {
        this.#userId = userId;
        this.#authToken = authToken;

        return new Promise((resolve, reject) => {
            if (!this.#userId || !this.#authToken) {
                this.#connectionStatus.value = 'disconnected';
                return reject(new Error('Missing userId or authToken'));
            }

            this.#connectionStatus.value = 'connecting';
            const url = this.#baseUrl;

            this.#socket = io(url, {
                query: {
                    userId: this.#userId,
                    conversationId: this.conversationId.value,
                    token: this.#authToken,
                },
                transports: ['websocket'],
            });

            this.#socket.on('connect', async () => {
                if (TRACE) {
                    log.trace('Connected to Chatbot server');
                }

                try {
                    await this.#authenticate();
                } catch (error) {
                    this.#connectionStatus.value = 'disconnected';
                    return reject(error);
                }

                this.#connectionStatus.value = 'connected';

                this.#handleResponses();

                resolve();
            });
        });
    }

    #connectionStatus = signal<AlphabotConnectionStatus>('uninitialized');
    get connectionStatus() {
        return this.#connectionStatus as ReadonlySignal<AlphabotConnectionStatus>;
    }

    #conversation = signal<MessageDocument[]>([]);
    get conversation() {
        return this.#conversation as ReadonlySignal<MessageDocument[]>;
    }

    #conversationId = signal<string | null>(null);
    get conversationId() {
        return this.#conversationId as ReadonlySignal<string | null>;
    }

    #conversationLoading = signal<boolean>(false);
    get conversationLoading() {
        return this.#conversationLoading as ReadonlySignal<boolean>;
    }

    #currentQuestion = signal<string>('');
    get currentQuestion() {
        return this.#currentQuestion as ReadonlySignal<string>;
    }

    constructor(private queryClient: ReactBindings['QueryClient']) {}

    #handleResponses(): void {
        if (!this.#socket) {
            throw new Error('Not connected to Chatbot server');
        }

        this.#socket.off('response');

        this.#socket.on('response', (data: string) => {
            let response: AlphabotWebsocketMessageBot;
            try {
                response = JSON.parse(data);
            } catch (error) {
                log.error('Failed to parse response from Chatbot server', { error, data });

                const errorMessage = new MessageDocument({
                    conversationId: this.#conversationId.value ?? '',
                    role: 'assistant',
                    type: 'error',
                    errorMessage: 'An error occurred while processing your request',
                    timestamp: Instant.now().toString(),
                    done: true,
                });

                this.#upsertCurrentMessage(errorMessage);

                return;
            }

            if (TRACE) {
                log.trace('Received response from Chatbot server', { response });
            }

            // if we have no conversation id, and this message has one, lets use that as our new conversation id
            if (!this.#conversationId.value && response.conversation_id) {
                this.#conversationId.value = response.conversation_id;

                // invalidate the conversation history query
                this.queryClient.invalidateQueries({
                    queryKey: makeChatHistoryKey(this.#userId!),
                });
            }

            // if our conversation id does not match the response, then we are getting responses for a conversation not being viewed
            // we can ignore this message
            if (this.#conversationId.value !== response.conversation_id) {
                return;
            }

            // lets check the last message to see if it has a conversation id,
            // if not, it is our placeholder message and we should now remove it
            const lastMessage = this.#conversation.value.at(-1);
            if (!lastMessage?.conversationId) {
                this.#conversation.value = this.#conversation.value.slice(0, -1);
            }

            // now that responses are coming in lets clear the current question
            // this will clear the question from the input field in the UI
            if (this.#currentQuestion.value !== '') {
                this.#currentQuestion.value = '';
            }

            // parse the current response into a MessageDocument
            // and then update or insert it into the conversation
            this.#upsertCurrentMessage(new MessageDocument(response));
        });
    }

    async loadConversation(conversationId: string): Promise<void> {
        if (!this.#userId) {
            log.error('Cannot load conversation: User ID is not defined');
            return;
        }

        this.#conversationId.value = conversationId;
        this.#conversation.value = [];
        this.#currentQuestion.value = '';
        this.#conversationLoading.value = true;

        try {
            const data = await this.queryClient.fetchUserQuery(getChatConversationQuery(this.#userId, conversationId));
            this.#conversation.value = data.conversation.map((c) => new MessageDocument(c));
        } catch (error) {
            log.error('Failed to load conversation:', { error, conversationId });
        } finally {
            this.#conversationLoading.value = false;
        }
    }

    #processing = computed<boolean>(() => {
        if (TRACE) {
            log.trace('processing', { conversation: this.#conversation.value });
        }
        const messages = this.#conversation.value;
        if (messages.length === 0) {
            return false;
        }

        const lastMessage = messages[messages.length - 1];
        return !lastMessage.done;
    });
    get processing() {
        return this.#processing;
    }

    #upsertCurrentMessage(newMessage: MessageDocument): void {
        if (TRACE) {
            log.trace('upsertCurrentMessage', { newMessage });
        }

        const currentMessage = this.#conversation.value.find((msg) => msg.role === 'assistant' && !msg.done);
        if (currentMessage) {
            this.#conversation.value = this.#conversation.value.map((msg) => {
                if (msg === currentMessage) {
                    return newMessage;
                }
                return msg;
            });
        } else {
            this.#conversation.value = [...this.#conversation.value, newMessage];
        }
    }

    sendMessage(): void {
        if (this.#connectionStatus.value !== 'connected') {
            throw new Error('Not connected to Chatbot server');
        }

        if (this.currentQuestion.value === '') {
            return;
        }

        const message = JSON.stringify({
            request_type: 'chat',
            query: this.currentQuestion.value,
            user_id: this.#userId,
            conversation_id: this.conversationId.value,
        });

        if (TRACE) {
            log.debug('Sending message to Chatbot server', { message });
        }

        this.#socket?.emit('chat', message);

        this.#conversation.value = [
            ...this.#conversation.value,
            new MessageDocument({
                conversation_id: this.#conversationId.value ?? '',
                role: 'user',
                message: this.currentQuestion.value,
                timestamp: Instant.now().toString(),
                done: false,
            }),
            new MessageDocument({
                conversation_id: this.#conversationId.value ?? '',
                role: 'assistant',
                payload: {
                    type: 'text',
                    currentText: 'Thinking...',
                },
                timestamp: Instant.now().toString(),
                done: false,
            }),
        ];
    }

    setCurrentQuestion(question: string) {
        this.#currentQuestion.value = question;
    }

    #socket: Socket | null = null;

    startNewConversation(): void {
        this.#conversation.value = [];
        this.#currentQuestion.value = '';
        this.#conversationId.value = null;
    }

    #userId: string | undefined;

    [Symbol.dispose](): void {
        if (this.#socket) {
            this.#socket.disconnect();
            log.debug('Chatbot connection closed');
        }
    }
}
