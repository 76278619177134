import { IndicatorParamModel } from './IndicatorParamModel';
import type { ConcreteIndicator, IndicatorRef } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { DictionaryFunctionDef } from 'src/lib/dictionaryModel';
import { mapIndicatorToDictionaryFunctionDef } from 'src/lib/util/dictionary';
import { getIndicatorFromRefQuery } from 'src/queries/dictionary';
import type { ReactBindings } from 'src/types/bindings';

/*
 * TODO: Come up with a strategy for either
 * - handling all model creation via IOC
 * - or finding a way to distinguish IOC-created models from vanilla models
 */

/**
 * Indicators are always fully "materialized"
 */
export class IndicatorModel implements IndicatorRef {
    /**
     * Underlying indicator POJO
     */
    #concreteIndicator: ConcreteIndicator;

    #params: IndicatorParamModel[];

    get symbol() {
        return this.#concreteIndicator.symbol;
    }

    get tags() {
        return this.#concreteIndicator.tags;
    }

    get name() {
        return this.#concreteIndicator.name;
    }

    get dataType() {
        return this.#concreteIndicator.dataType;
    }

    get enumeratedReturnValues() {
        return this.#concreteIndicator.enumeratedReturnValues;
    }

    get presets() {
        return this.#concreteIndicator.presets;
    }

    get formulaPresets() {
        return this.#concreteIndicator.presets.filter(
            (x): x is typeof x & { match: { type: 'formula' } } => x.match.type === 'formula',
        );
    }

    private constructor(concreteIndicator: ConcreteIndicator) {
        this.#concreteIndicator = concreteIndicator;
        this.#params = [];
        for (const param of concreteIndicator.params) {
            this.#params.push(new IndicatorParamModel(param));
        }
    }

    /**
     * @deprecated Prefer to create individual getters for each property of the concrete indicator
     */
    get concreteIndicator() {
        return this.#concreteIndicator;
    }

    static fromIndicator(concreteIndicator: ConcreteIndicator): IndicatorModel {
        return new IndicatorModel(concreteIndicator);
    }

    static async fromIndicatorRef(ref: IndicatorRef, queryClient: ReactBindings['QueryClient']) {
        const indicator = await queryClient.fetchUserQuery(getIndicatorFromRefQuery(ref));
        return new IndicatorModel(indicator);
    }

    doesReference(b: IndicatorRef) {
        return this.id === b.id && this.version === b.version;
    }

    static equals(a: IndicatorRef, b: IndicatorRef) {
        return a.id === b.id && a.version === b.version;
    }

    equals(b: ConcreteIndicator) {
        return this.id === b.id && this.version === b.version;
    }

    get id(): string {
        return this.#concreteIndicator.id;
    }

    get numRequiredParams() {
        return this.#concreteIndicator.params.filter((i) => !i.optional).length ?? 0;
    }

    get params() {
        return this.#params;
    }

    toDictionaryFunctionDef(): DictionaryFunctionDef {
        return mapIndicatorToDictionaryFunctionDef(this.#concreteIndicator);
    }

    toRef(): IndicatorRef {
        return {
            id: this.#concreteIndicator.id,
            version: this.#concreteIndicator.version,
        };
    }

    get unit() {
        return this.#concreteIndicator.unit;
    }

    get version(): number {
        return this.#concreteIndicator.version;
    }
}
