import type { NewsQueryFn, ChronologicalNewsWidgetModel, NewsRow } from './chronological';
import { computed, signal } from '@preact/signals-react';
import type { ReadonlySignal, Signal } from '@preact/signals-react';
import type { QueryObserverOptions } from '@tanstack/query-core';
import type { ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { TableCreationResultWithColumns } from '@thinkalpha/platform-ws-client/contracts/table.js';
import { from } from 'rxjs';
import type { IdeaDTO } from 'src/dtos/Idea';
import type { SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { ScannerPlanDTO } from 'src/features/scanner/dtos/ScannerPlanDTO';
import { ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import { WatchlistPlanDTO } from 'src/features/watchlist/dtos/WatchlistPlanDTO';
import { getBulkNewsBySymbol, getFullArticleContent } from 'src/lib/newQuant';
import type { FullNewsStory } from 'src/lib/newQuant/contracts';
import { createTable } from 'src/lib/table';
import type { Logger } from 'src/services/Logger';
import { createAlphaLensFromSymbol, createChartFromSymbol } from 'src/store/actions/container';
import { userSetNewsSymbolFilter, userSetNewsUniverse } from 'src/store/actions/widgets/news';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { NewsWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

type NewsWidgetModelState =
    | { type: 'initializing' }
    | {
          type: 'initialized';
          changeCloseSlug: Signal<ConcreteIndicator>;
          symbolSlug: Signal<ConcreteIndicator>;
          symbolFilter: Signal<string | null>;
          universeId: Signal<string | null>;
      };

@injectable()
export class ChronologicalNewsWidgetModelImpl extends ReactiveInjectable implements ChronologicalNewsWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('Logger') logger: Logger,
        @inject('DefaultUniverseServiceProvider')
        private readonly defaultUniverseServiceProvider: ReactBindings['DefaultUniverseServiceProvider'],
        @inject('SlugMapServiceProvider')
        private readonly slugMapServiceProvider: ReactBindings['SlugMapServiceProvider'],
        @inject('TableModel') private readonly table: ReactBindings['TableModel'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#state = { type: 'initializing' };

        this.#logger = logger.getSubLogger({ name: 'NewsWidgetModel' });
    }

    get fastIdeas(): ReadonlySignal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]> {
        return this.#fastIdeas as ReadonlySignal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]>;
    }

    #fastIdeas = signal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]>([]);

    async init(tabId: string) {
        this.widgetData.init(tabId);
        this.disposableStack.use(
            from(this.widgetData).subscribe(() => {
                const widget = this.widgetData.widget as NewsWidgetViewModel;
                if (widget.universeId !== this.#userSelectedUniverseId.value) {
                    if (TRACE) {
                        this.#logger.trace({ message: 'User selected new universe', universeId: widget.universeId });
                    }
                    this.#userSelectedUniverseId.value = widget.universeId;
                }

                // Keep symbolFilter signal in sync with widget data
                if (widget.symbolFilter !== this.#symbolFilterSignal.value) {
                    this.#symbolFilterSignal.value = widget.symbolFilter;
                }
            }),
        );

        // Initialize the symbolFilter signal with data from the widget
        this.#symbolFilterSignal.value = this.widget.symbolFilter;

        const defaultUniverseService = await this.defaultUniverseServiceProvider();
        const slugMapService = await this.slugMapServiceProvider();

        const symbolSlug = computed(() => slugMapService.slugMap.value['symbol']);
        const changeCloseSlug = computed(() => slugMapService.slugMap.value['symbol::changeClose']);

        const symbolFilter = signal<string | null>(null);

        const universeId = computed(
            () => this.#userSelectedUniverseId.value ?? defaultUniverseService.universe.value.id,
        );
        const queryOptions: Signal<QueryObserverOptions<TableCreationResultWithColumns>> = computed(() => ({
            queryKey: ['news-widget', 'table', universeId.value],
            queryFn: () => {
                return createTable({
                    leafStage: {
                        type: 'output',
                        columns: [
                            { id: 'symbol', type: 'indicator', indicator: symbolSlug.value },
                            { id: 'changeClose', type: 'indicator', indicator: changeCloseSlug.value },
                        ],
                        inputStage: {
                            type: 'row-source',
                            rowKey: 'ticker',
                            // @ts-expect-error Refactoring this to use universes correctly as part of chronological queries
                            universe: symbolFilter.value
                                ? {
                                      aspects: [],
                                      inclusionList: [symbolFilter.value],
                                      exclusionList: [],
                                      isTemplate: false,
                                  }
                                : (universeId.value ?? undefined),
                        },
                    },
                });
            },
        }));

        this.table.init({
            requestId: v4(),
            initBounds: { firstRow: 0, windowSize: 100000 },
            queryOptions,
        });

        // Use table updates$ directly without snapshots
        // Subscribe to updates$ for real-time changes
        this.disposableStack.use(
            this.table.updates$.subscribe((updates) => {
                if (!updates) return;

                // Process each update batch
                const currentSymbols = new Set(this.#tableSymbols.value);
                let hasChanges = false;

                for (const update of updates) {
                    if ('symbol' in update) {
                        const symbol = update['symbol'] as string;
                        if (symbol && !currentSymbols.has(symbol)) {
                            currentSymbols.add(symbol);
                            hasChanges = true;
                        }
                    }
                }

                // Only update the signal if we have changes
                if (hasChanges) {
                    this.#tableSymbols.value = Array.from(currentSymbols);
                    if (TRACE) {
                        this.#logger.trace('Updated symbols from updates$', { symbols: this.#tableSymbols.value });
                    }
                }
            }),
        );

        this.#state = {
            type: 'initialized',
            changeCloseSlug,
            symbolSlug,
            symbolFilter,
            universeId,
        };
    }

    #logger: Logger;

    onSelectSlowIdeas(ideas: (IdeaDTO<ScreenerPlanDTO> | IdeaDTO<WatchlistPlanDTO> | { universeId: string })[]): void {
        // Sync universe ID to redux
        const reduxUniverse = this.#userSelectedUniverseId.value;
        const selectedUniverse = ideas.find((idea) => 'universeId' in idea)?.universeId ?? null;

        if (reduxUniverse !== selectedUniverse) {
            this.store.dispatch(userSetNewsUniverse(this.widgetData.tabId, selectedUniverse));
        }
    }

    onSelectFastIdeas(ideas: (IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]): void {
        this.#fastIdeas.value = ideas;
    }

    onSelectSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    openChartForSymbol(symbol: string) {
        this.store.dispatch(
            createChartFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    openLensForSymbol(symbol: string) {
        this.store.dispatch(
            createAlphaLensFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    #resetState() {
        if (this.#state.type === 'initializing') {
            return;
        }

        this.store.dispatch(userSetNewsSymbolFilter(this.widgetData.tabId, null));

        // Go back to live mode
    }

    #state: NewsWidgetModelState;

    @reacts private set state(newState: NewsWidgetModelState) {
        this.#state = newState;
    }

    // Use a real signal for the symbolFilter to ensure reactivity
    #symbolFilterSignal = signal<string | null>(null);

    // Expose symbolFilter as a signal
    get symbolFilter(): ReadonlySignal<string | null> {
        return this.#symbolFilterSignal;
    }

    // Method to change the symbol filter
    onChangeSymbolFilter(value: string) {
        this.store.dispatch(userSetNewsSymbolFilter(this.widgetData.tabId, value === '' ? null : value));
    }

    // Track symbols from table snapshots
    #tableSymbols = signal<string[]>([]);

    // For external access to table symbols
    get symbols() {
        return this.#tableSymbols as ReadonlySignal<string[]>;
    }

    // Expose news from chronological query as a signal
    #news = signal<NewsRow[]>([]);

    get news() {
        return this.#news as ReadonlySignal<NewsRow[]>;
    }

    // Fetch full article content
    async fetchArticle(articleId: string): Promise<FullNewsStory> {
        return getFullArticleContent(articleId);
    }

    // Define the query function directly without a signal
    #chronologicalQueryFn: NewsQueryFn = async (
        symbols: string[] | null,
        prevRow: NewsRow | null,
        _abortSignal: AbortSignal,
    ): Promise<Iterable<NewsRow>> => {
        if (TRACE) {
            this.#logger.trace('Query function called', { symbols, prevRow });
        }
        if (!symbols || symbols.length === 0) return [];

        try {
            // Use the existing getBulkNewsBySymbol API to fetch news data
            const news = await getBulkNewsBySymbol({
                symbols: symbols,
                end: prevRow ? prevRow.publishedAt : undefined,
                maxResults: 50,
            });

            // Transform the news into the NewsRow format required by ChronologicalQueryManager
            return news.map((newsItem) => {
                return {
                    ...newsItem,
                    timestamp: newsItem.publishedAt, // Use publishedAt directly as the timestamp for chronological ordering
                } as NewsRow;
            });
        } catch (error) {
            this.#logger.error('Error fetching news', { error });
            return [];
        }
    };

    get newsQueryFn(): NewsQueryFn {
        return this.#chronologicalQueryFn;
    }

    get universe() {
        return this.widget.universeId;
    }

    set universe(universe: string | null) {
        this.#resetState();
        this.store.dispatch(userSetNewsUniverse(this.widgetData.tabId, universe));
    }

    #userSelectedUniverseId: Signal<string | null> = signal(null);

    get widget() {
        return this.widgetData.widget as NewsWidgetViewModel;
    }
}
