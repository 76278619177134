import { Instant } from '@js-joda/core';
import { doesAccessGrantAllowRequest } from '@thinkalpha/common/util/permissions.js';
import type { ConcreteIdea, Idea, NewIdea, ScannerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { IdeaDTO } from 'src/dtos/Idea';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { ScannerPlanDTO } from 'src/features/scanner/dtos/ScannerPlanDTO';
import { SCRANNER_CHANGE_EVENT } from 'src/features/scranner/dtos/ScrannerPlanDTO';
// import { getScannerRowCount } from 'src/features/scanner/lib/getScannerRowCount';
import type { ScannerWidgetModel } from 'src/models/ScannerWidgetModel';
// TODO make a mutation in src/queries/ideas to avoid this
// eslint-disable-next-line no-restricted-imports
import { createIdea, updateIdea } from 'src/queries/http/ideas';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import {
    setScannerDisplayingBuilder,
    setScannerDisplayingResults,
    setScannerIdea,
    storeScannerSplitPercentage,
    storeScannerUniverseId,
} from 'src/store/actions/widgets/scanner';
import type { ScannerWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class ScannerWidgetModelImpl extends ReactiveInjectable implements ScannerWidgetModel {
    #name: string;
    #description: string;

    get canSaveIdea() {
        if (!this.#plan.isDirty) {
            return false;
        }

        if ('id' in this.idea && 'permissions' in this.idea) {
            return doesAccessGrantAllowRequest(
                (this.idea as ConcreteIdea).permissions.effectiveDirectAccess || undefined,
                'writer',
            );
        }

        return false;
    }

    get columnTemplate() {
        return this.widget.columnTemplate;
    }

    createNewScanner(): void {
        this.lastSubmit = Instant.now();
        this.#plan = new ScannerPlanDTO(undefined, this.queryClient, this.formulaService);
        this.#name = 'New Scanner';
        this.#description = '';

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(
                {
                    name: this.#name,
                    description: this.#description,
                    defaultUniverseId: null,
                    defaultColumnTemplateId: null,
                    isTemplate: false,
                },
                this.#plan,
            ) as IdeaDTO<ScannerPlanDTO>,
        );

        this.#initNewScanner();
    }

    constructor(
        @inject('ImportsManagerModel') @reacts public importsManager: ReactBindings['ImportsManagerModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('ResultsAreaModel') public resultsAreaModel: ReactBindings['ResultsAreaModel'],
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('FormulaService') private formulaService: ReactBindings['FormulaService'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
        this.#plan = new ScannerPlanDTO(undefined, queryClient, formulaService);
        this.#name = 'New Scanner';
        this.#description = '';
    }

    #hasChangesSinceLastSubmit = false;

    async init(tabId: string) {
        this.widgetData.init(tabId);

        // if we have an idea, set up the plan from it
        if (this.idea) {
            this.#name = this.idea.name ?? '';
            this.#description = this.idea.description ?? '';

            if (this.idea.plan.type === 'scanner') {
                this.#plan = await ScannerPlanDTO.fromPlan(this.idea.plan, this.queryClient, this.formulaService);
                this.#onScannerChange();
            }
        }

        this.#initNewScanner();
    }

    #initNewScanner() {
        // trigger a submit, so the results view loads any time a new scanner is used
        this.lastSubmit = Instant.now();

        // set up event bindings
        const listener = this.#onScannerChange.bind(this);
        this.#plan.changeTarget.addEventListener(SCRANNER_CHANGE_EVENT, listener);
        this.disposableStack.defer(() => {
            this.#plan.changeTarget.removeEventListener(SCRANNER_CHANGE_EVENT, listener);
        });
        this.#plan.changeTarget.dispatchEvent(new Event(SCRANNER_CHANGE_EVENT));
    }

    get isShowingBuilder() {
        return this.widget.displayingForm;
    }

    set isShowingBuilder(value: boolean) {
        this.store.dispatch(setScannerDisplayingBuilder(this.widgetData.tabId, value));
    }

    get isShowingResults() {
        return this.widget.displayingResults;
    }

    set isShowingResults(value: boolean) {
        this.store.dispatch(setScannerDisplayingResults(this.widgetData.tabId, value));
    }

    #lastSubmit = Instant.now();
    get lastSubmit() {
        return this.#lastSubmit;
    }

    @reacts set lastSubmit(value: Instant) {
        this.#lastSubmit = value;
    }

    #rowCountFiltered = 0;
    get rowCountFiltered() {
        return this.#rowCountFiltered;
    }

    @reacts set rowCountFiltered(value: number) {
        this.#rowCountFiltered = value;
    }

    #rowCountTotal = 0;
    get rowCountTotal() {
        return this.#rowCountTotal;
    }

    @reacts set rowCountTotal(value: number) {
        this.#rowCountTotal = value;
    }

    #onScannerChange() {
        // TODO add back the row count, copy mostly from ScreenerWidgetModel

        // this will be called each time the scanner changes, including when we first load it.
        // so set hasChangesSinceLastSubmit to true if the scanner is dirty
        this.#hasChangesSinceLastSubmit = this.#plan.isDirty;

        this.rerender();
    }

    async saveIdea(): Promise<ConcreteIdea> {
        const canPut = (idea: Idea | NewIdea): idea is ConcreteIdea => {
            return Boolean(this.idea.id);
        };

        const contractIdea = this.toIdea();

        const ideaToSave = canPut(contractIdea) ? contractIdea : null;

        if (!ideaToSave) {
            throw new Error('Cannot save idea without an id');
        }

        const newIdea = (await updateIdea(ideaToSave)) as ConcreteIdea & {
            plan: ScannerPlan;
        };

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(
                newIdea,
                await ScannerPlanDTO.fromPlan(newIdea.plan, this.queryClient, this.formulaService),
            ) as IdeaDTO<ScannerPlanDTO>,
        );

        return newIdea;
    }

    async saveIdeaAs(name: string): Promise<ConcreteIdea> {
        const ideaToSave = this.toIdea(name);

        if (!ideaToSave.name) {
            throw new Error('Cannot save idea without a name');
        }

        const newIdea = (await createIdea(ideaToSave)) as ConcreteIdea & {
            plan: ScannerPlan;
        };

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(
                newIdea,
                await ScannerPlanDTO.fromPlan(newIdea.plan, this.queryClient, this.formulaService),
            ) as IdeaDTO<ScannerPlanDTO>,
        );

        return newIdea;
    }

    toIdea(name?: string): NewIdea {
        return {
            ...this.idea,
            name: name ?? this.#name,
            description: this.#description,
            plan: this.#plan.toContract(),
        };
    }

    #plan: ScannerPlanDTO;

    get scanner() {
        return this.#plan;
    }

    get splitPercentage() {
        return this.widget.splitPercentage;
    }

    set splitPercentage(value: number) {
        this.store.dispatch(storeScannerSplitPercentage(this.widgetData.tabId, value));
    }

    get canSubmit() {
        return (
            this.#hasChangesSinceLastSubmit &&
            this.#plan.conditions.every((condition) => {
                if (!condition.enabled) return true;

                if (condition.type === 'formula') {
                    return Boolean(condition.formula);
                }

                if (
                    !(
                        Boolean(condition.selection) ||
                        condition.indicator.params.every((x) => x.defaultValue !== undefined || x.optional)
                    )
                ) {
                    return false;
                }

                switch (condition.type) {
                    case 'quantitative':
                        if (!condition.max && !condition.min) return false;

                        const invalidNumbers =
                            condition.max &&
                            condition.min &&
                            (condition.min.value > condition.max.value ||
                                (condition.min.mode === 'exclusive' &&
                                    condition.max?.mode === 'exclusive' &&
                                    condition.min.value === condition.max.value));

                        if (invalidNumbers) return false;

                        return true;
                    case 'membership':
                    case 'equality':
                        return true;
                }
            })
        );
    }

    submit() {
        this.lastSubmit = Instant.now();
        this.#hasChangesSinceLastSubmit = false;
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    get idea() {
        return this.widget.idea;
    }

    get universeId() {
        return this.widget.universeId;
    }

    set universeId(value: string | null) {
        this.store.dispatch(storeScannerUniverseId(this.widgetData.tabId, value));
    }

    async useIdea(idea: IdeaDTO<ScannerPlanDTO>) {
        this.store.dispatch(
            setScannerIdea(this.widgetData.tabId, {
                ...idea.idea,
                plan: idea.plan.toContract(),
            }),
        );

        this.#name = idea.name ?? '';
        this.#description = idea.idea.description ?? '';

        this.#plan = idea.plan;
        this.#initNewScanner();
    }

    private get widget() {
        return this.widgetData.widget as ScannerWidgetViewModel;
    }
}
