import { IndicatorParamModel } from '../Indicator/IndicatorParamModel';
import type {
    ConcreteIndicator,
    IndicatorImport,
    IndicatorImportRef,
    IndicatorRef,
} from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { IndicatorImportRefModel } from 'src/dtos/IndicatorImportRef';
import type { DictionaryFunctionDef } from 'src/lib/dictionaryModel';
import { mapIndicatorToDictionaryFunctionDef } from 'src/lib/util/dictionary';
import { getIndicatorFromRefQuery } from 'src/queries/dictionary';
import type { ReactBindings } from 'src/types/bindings';

/*
 * TODO: Come up with a strategy for either
 * - handling all model creation via IOC
 * - or finding a way to distinguish IOC-created models from vanilla models
 */

/**
 * Indicator imports are always fully "materialized"
 */
export class IndicatorImportModel implements IndicatorImportRef {
    #alias: string;

    /**
     * Underlying indicator POJO
     */
    #concreteIndicator: ConcreteIndicator;

    #params: IndicatorParamModel[];

    get alias(): string {
        return this.#alias;
    }

    get symbol() {
        return this.#concreteIndicator.symbol;
    }

    private constructor(alias: string, concreteIndicator: ConcreteIndicator) {
        this.#alias = alias;
        this.#concreteIndicator = concreteIndicator;
        this.#params = [];
        for (const param of concreteIndicator.params) {
            this.#params.push(new IndicatorParamModel(param));
        }
    }

    /**
     * @deprecated Prefer to create individual getters for each property of the concrete indicator
     */
    get concreteIndicator() {
        return this.#concreteIndicator;
    }

    static fromIndicatorImport({ alias, ...concreteIndicator }: IndicatorImport): IndicatorImportModel {
        return new IndicatorImportModel(alias, concreteIndicator);
    }

    static async fromIndicatorImportRef(ref: IndicatorImportRef, queryClient: ReactBindings['QueryClient']) {
        const indicator = await queryClient.fetchUserQuery(getIndicatorFromRefQuery(ref));
        return new IndicatorImportModel(ref.alias, indicator);
    }

    static fromIndicatorAndImports(
        concreteIndicator: ConcreteIndicator,
        imports: readonly IndicatorImportModel[],
        preferredAlias?: string,
    ) {
        const importModel = imports.find((importModel) => importModel.doesReference(concreteIndicator));

        if (importModel) {
            return importModel;
        }

        const aliasPrefix = preferredAlias ? preferredAlias : concreteIndicator.symbol;
        let alias = aliasPrefix;
        let found;
        let i = 0;
        do {
            found = imports.find((importModel) => importModel.alias === alias);

            if (found && !found.doesReference(concreteIndicator)) {
                i++;
                alias = `${aliasPrefix}_${i}`;
            }
        } while (found);

        return new IndicatorImportModel(alias, concreteIndicator);
    }

    static fromExistingImportModel(existingImport: IndicatorImportModel, imports: readonly IndicatorImportModel[]) {
        return IndicatorImportModel.fromIndicatorAndImports(
            existingImport.#concreteIndicator,
            imports,
            existingImport.alias,
        );
    }

    static fromIndicator(concreteIndicator: ConcreteIndicator, alias: string) {
        return new IndicatorImportModel(alias, concreteIndicator);
    }

    doesReference(b: IndicatorRef) {
        return this.id === b.id && this.version === b.version;
    }

    static equals(a: IndicatorImportRef, b: IndicatorImportRef) {
        return a.id === b.id && a.version === b.version && a.alias === b.alias;
    }

    equals(b: IndicatorImport) {
        return this.id === b.id && this.version === b.version && this.alias === b.alias;
    }

    /**
     *
     * @param ref
     * @param alias null = use indicator's symbol
     * @param queryClient
     * @returns
     */
    static async fromIndicatorRef(ref: IndicatorRef, alias: string | null, queryClient: ReactBindings['QueryClient']) {
        const indicator = await queryClient.fetchUserQuery(getIndicatorFromRefQuery(ref));
        return new IndicatorImportModel(alias ?? indicator.symbol, indicator);
    }

    get id(): string {
        return this.#concreteIndicator.id;
    }

    get isCustomAliased(): boolean {
        return this.#alias !== this.#concreteIndicator.symbol;
    }

    get numRequiredParams() {
        return this.#concreteIndicator.params.filter((i) => !i.optional).length ?? 0;
    }

    get params() {
        return this.#params;
    }

    toDictionaryFunctionDef(): DictionaryFunctionDef {
        return mapIndicatorToDictionaryFunctionDef(this.#concreteIndicator);
    }

    toRef(): IndicatorImportRefModel {
        return IndicatorImportRefModel.fromIndicatorImportRef({
            alias: this.#alias,
            id: this.#concreteIndicator.id,
            version: this.#concreteIndicator.version,
        });
    }

    get version(): number {
        return this.#concreteIndicator.version;
    }

    toContractType(): IndicatorImport {
        return { ...this.#concreteIndicator, alias: this.#alias };
    }
}
