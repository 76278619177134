import { DateTimeFormatter, LocalDate, LocalTime, ZoneId } from '@js-joda/core';
import { mapSkipLogErrors } from '@thinkalpha/common/util/mapSkip.js';
import { container } from 'src/StaticContainer';
import { createInstance } from 'src/api';
import type { EventType, Event, EventBase } from 'src/components/ui/EventList';
import type { SchemaType } from 'src/features/quant/schema';
import { appConfig } from 'src/lib/config';
import { UnreachableCaseError } from 'src/lib/util/unreachableCaseError';

const quantApi = createInstance({ baseURL: appConfig.quantApi });

export const log = container.get('Logger').getSubLogger({ name: 'EventsApi' });

const dintFormatter = DateTimeFormatter.ofPattern('yyyyMMdd');
const dintParser = (dint: number | string) =>
    dint !== undefined && dint !== null
        ? LocalDate.parse(dint.toString().replace('.0', '').replaceAll('-', ''), dintFormatter)
        : undefined;

const shittyRatioParser = (ratio: string): { value: number; direction: 'forward' | 'reverse' } => {
    const [part1Str, part2Str] = ratio
        .toString()
        .split('for')
        .map((x) => x.trim());
    const part1 = parseFloat(part1Str);
    const part2 = parseFloat(part2Str);
    if (part1 === 1) {
        return { value: part2, direction: 'reverse' };
    } else {
        return { value: part1, direction: 'forward' };
    }
};

type EventRequest = SchemaType['/v1/corporate-actions/query']['POST']['args']['body'];
type EventResponse = SchemaType['/v1/corporate-actions/query']['POST']['data'];
type ActionType = NonNullable<EventResponse['response']>[0]['actionType'];

const arbitraryAnchor = LocalTime.parse('13:00:00');

export async function getEvents({
    symbols,
    eventTypes,
    start,
    end,
    referenceId,
    count,
    abortSignal,
}: {
    symbols?: string[];
    eventTypes?: EventType[];
    start?: LocalDate;
    end?: LocalDate;
    referenceId?: string;
    count?: number;
    abortSignal?: AbortSignal;
}) {
    const actionTypes = eventTypes?.flatMap((x) => EventTypeToActionTypes[x]);

    const res = await quantApi.post<EventResponse>(
        `/v1/corporate-actions/query`,
        {
            symbols,
            start: start?.atTime(arbitraryAnchor).atZone(ZoneId.UTC).toString(),
            end: end?.atTime(arbitraryAnchor).atZone(ZoneId.UTC).toString(),
            actionTypes: actionTypes?.length ? actionTypes : undefined,
            direction: 'find_older',
            referenceId,
            limit: count,
        } satisfies EventRequest,
        { signal: abortSignal },
    );

    try {
        const mapped =
            res.data.response &&
            mapSkipLogErrors(
                res.data.response,
                (event): Event | undefined => {
                    const base = {
                        eventId: event.id,
                        exDate: dintParser(event.ExDate)!,
                        symbol: event.Symbol,
                    } satisfies Partial<EventBase>;
                    switch (event.actionType) {
                        case 'Dividend':
                            return {
                                ...base,
                                type: 'dividend',
                                cashAmount: event.CashDividendPerShare ?? undefined,
                                stockAmount: event.StockDividendPerShare ?? undefined,
                                yield: event.Yield === null ? undefined : event.Yield / 100,
                                ...(event.Ratio && {
                                    ratio: shittyRatioParser(event.Ratio).value,
                                    ratioDirection: shittyRatioParser(event.Ratio).direction,
                                }),
                                symbolYielded: event.SpinoffSymbol ?? undefined,
                                dividendType: event.Type,
                            };
                        case 'Earnings':
                            return {
                                ...base,
                                type: 'earnings',
                                eps: event.EPS ?? undefined,
                                revenue: event.Revenue ?? undefined,
                            };
                        case 'Split':
                            return {
                                ...base,
                                type: 'split',
                                symbol: event.Symbol,
                                reverse: event.Type === 'Reverse',
                                ratio: event.Ratio ? shittyRatioParser(event.Ratio).value : undefined,
                                newPrice: event.PriceAfter ?? undefined,
                                originalPrice: event.PriceBefore ?? undefined,
                            };
                        case 'NewListing':
                            return {
                                ...base,
                                type: 'ipo',
                                shares: event.SharesOffered ?? undefined,
                                price: event.Price ?? undefined,
                            };
                        case 'PrimaryExchangeChange':
                            return {
                                ...base,
                                type: 'primary-exchange-change',
                                changeType: event.Type,
                                oldExchange: event.OldExchange ?? undefined,
                                newExchange: event.NewExchange ?? undefined,
                            };
                        case 'SymbolChange':
                            return {
                                ...base,
                                type: 'symbol-change',
                                oldSymbol: event.OldSymbol,
                                newSymbol: event.NewSymbol,
                            };
                        case 'Acquisition':
                            return {
                                ...base,
                                type: 'acquisition',
                                acquirerSymbol: event.AcquirerSymbol ?? undefined,
                                cashPerShare: event.CashPerShare ?? undefined,
                                stockPerShare: event.StockPerShare ?? undefined,
                            };
                        case 'SharesOutstandingChange':
                            return {
                                ...base,
                                type: 'shares-outstanding-change',
                                oldShareCount: event.OldSharesOutstanding ?? undefined,
                                newShareCount: event.NewSharesOutstanding,
                            };
                        case 'StockBuyback':
                            return {
                                ...base,
                                type: 'buyback',
                                completionDate: LocalDate.parse(event.CompletionDate),
                            };
                        default:
                            throw new UnreachableCaseError(event);
                    }
                },
                (elem, err) => log.warn(`Failed to map event ${elem?.id}`, { elem, error: err }),
                (e) => e instanceof Error,
            );

        return mapped?.filter((e) => e !== undefined) ?? [];
    } catch (e) {
        log.error('Failed to map events', { error: e });
        throw e;
    }
}

const EventTypeToActionTypes: Record<EventType, ActionType[]> = {
    ipo: ['NewListing'],
    'symbol-change': ['SymbolChange'],
    'primary-exchange-change': ['PrimaryExchangeChange'],
    earnings: ['Earnings'],
    dividend: ['Dividend'],
    split: ['Split'],
    'shares-outstanding-change': ['SharesOutstandingChange'],
    acquisition: ['Acquisition'],
    buyback: ['StockBuyback'],
};
