import type { TickerEditFields } from '../Tickers/TickerEdit';
import type { DictionaryBackedData } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type {
    ToolbarItem,
    ToolbarTickerItem,
    ToolbarClockItem,
    NewToolbarItem,
} from '@thinkalpha/platform-ws-client/contracts/user-preference.js';
import { UnreachableCaseError } from 'src/lib/util/unreachableCaseError';
import { v4 } from 'uuid';

export abstract class ToolbarItemDTO {
    constructor(item: ToolbarItem) {
        this.#id = v4();
        this.#type = item.type;
        this.#displayName = item.displayName;
    }

    static from(item: ToolbarItem): ToolbarItemDTO {
        switch (item.type) {
            case 'ticker':
                return new ToolbarTickerItemDTO(item);
            case 'clock':
                return new ToolbarClockItemDTO(item);
            default:
                throw new UnreachableCaseError(item);
        }
    }

    #displayName: string | undefined;
    get displayName(): string | undefined {
        return this.#displayName;
    }

    set displayName(value: string | undefined) {
        this.#displayName = value;
    }

    #id: string;
    get id(): string {
        return this.#id;
    }

    abstract toJSON(): NewToolbarItem;

    #type: 'ticker' | 'clock';
    get type(): 'ticker' | 'clock' {
        return this.#type;
    }
}

export class ToolbarTickerItemDTO extends ToolbarItemDTO {
    constructor(item: ToolbarTickerItem) {
        if (item.type !== 'ticker') {
            throw new Error('Invalid item type');
        }

        super(item);

        this.#symbol = item.symbol;
        this.#items = item.items;
    }

    #items: ToolbarTickerItem['items'] | DictionaryBackedData[];
    get items(): ToolbarTickerItem['items'] | DictionaryBackedData[] {
        return this.#items;
    }

    #symbol: string;
    get symbol(): string {
        return this.#symbol;
    }

    update(
        data: Pick<
            TickerEditFields,
            'displayName' | 'symbol' | 'primaryFormula' | 'secondaryFormula' | 'primaryImports' | 'secondaryImports'
        >,
        defaultFormulas?: DictionaryBackedData[],
    ) {
        const [defaultPrimaryItem, defaultSecondaryItem] = defaultFormulas ?? [];

        this.displayName = data.displayName;
        this.#symbol = data.symbol;
        this.#items = [
            data.primaryFormula.formula && data.primaryImports
                ? {
                      formula: data.primaryFormula.formula ?? '',
                      imports: data.primaryImports,
                      type: 'formula',
                  }
                : defaultPrimaryItem,
            data.secondaryFormula.formula && data.secondaryImports
                ? {
                      formula: data.secondaryFormula.formula ?? '',
                      imports: data.secondaryImports,
                      type: 'formula',
                  }
                : defaultSecondaryItem,
        ].filter(Boolean) as DictionaryBackedData[];
    }

    toJSON(): NewToolbarItem {
        return {
            type: 'ticker',
            displayName: this.displayName,
            symbol: this.symbol,
            items: this.items,
        };
    }
}

export class ToolbarClockItemDTO extends ToolbarItemDTO {
    constructor(item: ToolbarClockItem) {
        if (item.type !== 'clock') {
            throw new Error('Invalid item type');
        }

        super(item);

        this.#timezone = item.timezone;
        this.#is24Hour = item.is24Hour;
    }

    #timezone: string;
    get timezone(): string {
        return this.#timezone;
    }

    #is24Hour: boolean;
    get is24Hour(): boolean {
        return this.#is24Hour;
    }

    update(data: ToolbarClockItem) {
        this.displayName = data.displayName;
        this.#timezone = data.timezone;
        this.#is24Hour = data.is24Hour;
    }

    toJSON(): NewToolbarItem {
        return {
            type: 'clock',
            displayName: this.displayName,
            timezone: this.timezone,
            is24Hour: this.is24Hour,
        };
    }
}
