import { createStructuredSelector } from 'reselect';
import { container } from 'src/StaticContainer';
import { ReactiveInjectable, reacts, injectable, inject } from 'src/features/ioc';
import type { WidgetDataModel } from 'src/models/WidgetDataModel';
import type { RootState } from 'src/store';
import { onTabChannelChange, resetChannelData } from 'src/store/actions/widgetAndChannel';
import type { ReactBindings } from 'src/types/bindings';

const selectWidgetData = createStructuredSelector({
    tab: (state: RootState, tabId: string) => state.tab.byId[tabId],
});

type WidgetData = ReturnType<typeof selectWidgetData>;

const log = container.get('Logger').getSubLogger({ name: 'WidgetDataModel' });

@injectable()
export class WidgetDataModelImpl extends ReactiveInjectable implements WidgetDataModel {
    #widgetData!: WidgetData;

    constructor(@inject('Store') private store: ReactBindings['Store']) {
        super();
    }

    changeChannel(channelId: string | null) {
        this.store.dispatch(onTabChannelChange(this.#widgetData.tab.id, channelId));
    }

    resetChannelData() {
        this.store.dispatch(resetChannelData(this.#widgetData.tab.id));
    }

    get channelId() {
        return this.#widgetData.tab.channelId;
    }

    get displayName() {
        return this.#widgetData.tab.name || '';
    }

    init(tabId: string) {
        this.#widgetData = selectWidgetData(this.store.getState(), tabId);

        const unsubscribe = this.store
            .subscribeSelector((state) => selectWidgetData(state, tabId))
            .handle((widgetData) => {
                this.widgetDataReactor = widgetData;
            });

        this.disposableStack.use({
            [Symbol.dispose]: () => {
                log.debug({ message: 'disposing widget data store subscription' });
                unsubscribe();
            },
        });
    }

    get tabId() {
        return this.#widgetData.tab.id;
    }

    get containerId() {
        return this.#widgetData.tab.containerId;
    }

    get widget() {
        return this.#widgetData.tab.widget;
    }

    @reacts set widgetDataReactor(widgetData: WidgetData) {
        this.#widgetData = widgetData;
    }
}
