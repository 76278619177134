import { lexer } from '@thinkalpha/language-services';
import type { ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { SelfAliasIndicatorFormula } from '@thinkalpha/platform-ws-client/contracts/ideas/conditions.js';
import { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import type { IndicatorPresetModel } from 'src/dtos/IndicatorPreset';
import { toSpliced } from 'src/lib/util/toSpliced';
import { getIndicatorFromRefQuery } from 'src/queries/dictionary';
import type { IsomorphicBindings } from 'src/types/bindings';

export async function getPresetFormulaAndImports(
    selfIndicator: ConcreteIndicator,
    imports: readonly IndicatorImportModel[],
    selectedPreset: IndicatorPresetModel | SelfAliasIndicatorFormula | undefined,
    queryClient: IsomorphicBindings['QueryClient'],
): Promise<[formula: string, addedImports: IndicatorImportModel[]]> {
    const addedImports: IndicatorImportModel[] = [];

    const selfImportModel = IndicatorImportModel.fromIndicatorAndImports(selfIndicator, imports);
    if (!imports.includes(selfImportModel)) {
        addedImports.push(selfImportModel);
    }

    if (!selectedPreset) {
        return [`${selfImportModel.alias}()`, addedImports]; // `indicator()`
    }

    // otherwise we have a formula call with parameters
    const appliedFilterAliasToRenamedAliasMap = new Map<string, string>();
    for (const imp of selectedPreset.imports) {
        const fullImport = await queryClient.fetchUserQuery(getIndicatorFromRefQuery(imp));
        const importModel = IndicatorImportModel.fromIndicatorAndImports(fullImport, imports);
        // importModel may be returned from the array of imports so we can use referential equality check
        if (!imports.includes(importModel)) {
            addedImports.push(importModel);
        }
    }

    const tokens = lexer(selectedPreset.formula);
    let replacementFormula = selectedPreset.formula;
    for (const token of tokens.toReversed()) {
        if (token.token === selectedPreset.selfAlias) {
            // referring to ourself, so rename self alias to be the name we've given our import
            replacementFormula = toSpliced(
                replacementFormula,
                token.range.start,
                token.range.end - token.range.start,
                selfImportModel.alias,
            );
            continue;
        }

        // check if token is one of our imports that now must be renamed
        const ourAlias = appliedFilterAliasToRenamedAliasMap.get(token.token);
        if (!ourAlias) continue;

        replacementFormula = toSpliced(
            replacementFormula,
            token.range.start,
            token.range.end - token.range.start,
            ourAlias,
        );
    }

    return [replacementFormula, addedImports];
}
