import { iterateAst } from '@thinkalpha/language-services';
import type { KnownAstNode } from '@thinkalpha/language-services';

export const modifyCallNodesByAlias = (root: KnownAstNode, aliasBefore: string, aliasAfter: string): KnownAstNode => {
    const modified = { ...root };

    iterateAst(modified).map((node) => {
        if (node.type !== 'call') return;

        if (node.source === aliasBefore) {
            return {
                ...node,
                source: aliasAfter,
            };
        }

        return node;
    });

    return modified;
};
