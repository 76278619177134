import { getTopStrategiesRecordsForUser } from './http/strategies';
import type { FetchQueryOptions, QueryObserverOptions } from '@tanstack/react-query';
import type { Idea, TopIdeas } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import type { StrategyRecord, TopStrategies } from 'src/contracts/strategy';
import { getStrategyRecordsQuery as getStrategyRecordsQueryService } from 'src/lib/strategies';
import { ONE_HOUR } from 'src/lib/util/timeConstants';
import type { IsomorphicBindings } from 'src/types/bindings';

// Constants
export const STRATEGIES = 'strategies';
export const TOP = 'top';

/**
 * Get top strategies for a user
 *
 * @returns an object with queryKey and queryFn
 */
export function getTopStrategiesForUserQuery(): FetchQueryOptions<TopStrategies> {
    return {
        queryKey: [STRATEGIES, TOP],
        queryFn: () => getTopStrategiesRecordsForUser(),
        staleTime: ONE_HOUR,
    };
}

export function upsertRecentlyCreatedStrategyQueryData(
    strategyToUpsert: Idea,
    queryClient: IsomorphicBindings['QueryClient'],
) {
    queryClient.setUserQueryData([STRATEGIES, TOP], (topStrategies: TopIdeas) => {
        return {
            recentlyAccessed: [
                strategyToUpsert,
                ...topStrategies.recentlyAccessed.filter((x) => x.id !== strategyToUpsert.id),
            ],
            recentlyCreated: [
                strategyToUpsert,
                ...topStrategies.recentlyCreated.filter((x) => x.id !== strategyToUpsert.id),
            ],
        };
    });
}

export function updateTopStrategiesStrategyName(
    strategyToRename: Idea,
    queryClient: IsomorphicBindings['QueryClient'],
) {
    queryClient.setUserQueryData([STRATEGIES, TOP], (topStrategies: TopIdeas) => {
        return {
            recentlyAccessed: topStrategies.recentlyAccessed.map((x) =>
                x.id === strategyToRename.id ? { ...x, name: strategyToRename.name } : x,
            ),
            recentlyCreated: topStrategies.recentlyCreated.map((x) =>
                x.id === strategyToRename.id ? { ...x, name: strategyToRename.name } : x,
            ),
        };
    });
}

export function removeStrategyQueryDataStrategy(id: string, queryClient: IsomorphicBindings['QueryClient']) {
    queryClient.setUserQueryData([STRATEGIES, TOP], (topStrategies: TopIdeas) => {
        return {
            recentlyAccessed: topStrategies.recentlyAccessed.filter((x) => x.id !== id),
            recentlyCreated: topStrategies.recentlyCreated.filter((x) => x.id !== id),
        };
    });
}

/**
 * Generates a query to get a strategy records query
 *
 * @param resourceQuery a ResourceQuery, with query details
 * @returns an object with queryKey and queryFn
 */
export function getStrategyRecordsQuery(
    resourceQuery: ResourceQuery,
    enabled = true,
): QueryObserverOptions<ResourceQueryResponseWithMeta<StrategyRecord>> {
    return {
        queryKey: ['strategy-records', resourceQuery],
        queryFn: () => getStrategyRecordsQueryService(resourceQuery),
        enabled,
    };
}
