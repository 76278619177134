import { TopItemSchema, TopItemsSchema } from '.';
import type { LocalTopItems, TopItem, TopItemsService } from '.';
import { injectable, inject } from 'src/features/ioc';
import { invalidateTopMenuItemsForKey } from 'src/queries/topMenuItems';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class TopItemsServiceImpl implements TopItemsService {
    #MAX_PER_TYPE = 20;

    constructor(
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('ConfigService') private configService: ReactBindings['ConfigService'],
    ) {}

    #getStoredData(): LocalTopItems {
        const storedData = localStorage.getItem(this.#storageKey);

        if (!storedData) {
            return { recentlyAccessed: [], recentlyCreated: [] };
        }

        const { data, error } = TopItemsSchema.safeParse(JSON.parse(storedData));

        if (error) {
            return { recentlyAccessed: [], recentlyCreated: [] };
        }

        return data;
    }

    #storeData(data: LocalTopItems): void {
        localStorage.setItem(this.#storageKey, JSON.stringify(data));
        invalidateTopMenuItemsForKey(this.queryClient);
    }

    getItems(opts: { types?: TopItem['type'][]; limit?: number } = {}): LocalTopItems {
        let { recentlyAccessed, recentlyCreated } = this.#getStoredData();

        const { limit, types } = opts;

        if (types?.length) {
            recentlyAccessed = recentlyAccessed.filter((item) => types.includes(item.type));
            recentlyCreated = recentlyCreated.filter((item) => types.includes(item.type));
        }

        if (limit) {
            recentlyAccessed = recentlyAccessed.slice(0, limit);
            recentlyCreated = recentlyCreated.slice(0, limit);
        }

        return { recentlyAccessed, recentlyCreated };
    }

    addRecentlyAccessed(newItem: TopItem): void {
        const data = this.#getStoredData();
        const filtered = data.recentlyAccessed.filter((item) => item.id !== newItem.id);
        data.recentlyAccessed = limitArrayByType(
            [
                // Using parse to strip extra keys and keep localStorage small
                TopItemSchema.parse(newItem),
                ...filtered,
            ],
            this.#MAX_PER_TYPE,
        );
        this.#storeData(data);
    }

    addRecentlyCreated(newItem: TopItem): void {
        const data = this.#getStoredData();
        const filtered = data.recentlyCreated.filter((item) => item.id !== newItem.id);
        data.recentlyCreated = limitArrayByType(
            [
                // Using parse to strip extra keys and keep localStorage small
                TopItemSchema.parse(newItem),
                ...filtered,
            ],
            this.#MAX_PER_TYPE,
        );
        this.#storeData(data);
    }

    delete(id: string): void {
        const data = this.#getStoredData();
        data.recentlyAccessed = data.recentlyAccessed.filter((item) => item.id !== id);
        data.recentlyCreated = data.recentlyCreated.filter((item) => item.id !== id);
        this.#storeData(data);
    }

    get #storageKey() {
        return `top_items_${this.configService.environmentName}`;
    }
}

const limitArrayByType = <T extends { type: string }>(filtered: T[], maxItemsPerType: number): T[] => {
    const result: T[] = [];
    const countByType: Record<string, number> = {};

    // Iterate over the filtered array to enforce the maxItemsPerType limit
    for (const item of filtered) {
        const { type } = item;

        // Initialize the count for the type if it doesn't exist yet
        if (!countByType[type]) {
            countByType[type] = 0;
        }

        // Only add the item if we haven't exceeded the limit for this type
        if (countByType[type] < maxItemsPerType) {
            result.push(item);
            countByType[type]++;
        }
    }

    return result;
};
