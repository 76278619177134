import type { MessagesActions } from 'src/store/actions/widgets/messages';
import type { MessagesWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const messagesReducer = (
    state: WidgetTabViewModel<MessagesWidgetViewModel>,
    action: MessagesActions,
): WidgetTabViewModel<MessagesWidgetViewModel> => {
    switch (action.type) {
        case 'updateMessagesSymbolFilter': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbolFilter: action.symbol,
                },
            };
        }
        case 'updateMessagesAccount': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    accountIds: action.accountIds,
                },
            };
        }

        default:
            return state;
    }
};
