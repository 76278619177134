import type { SearchAlphaActions } from 'src/store/actions/widgets/searchAlpha';
import type { SearchAlphaWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const searchAlphaReducer = (
    state: WidgetTabViewModel<SearchAlphaWidgetViewModel>,
    action: SearchAlphaActions,
): WidgetTabViewModel<SearchAlphaWidgetViewModel> => {
    switch (action.type) {
        case 'setSearchAlphaDisplayingResults': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    displayingResults: action.isShowing,
                },
            };
        }

        case 'setSearchAlphaDisplayingSearch': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    displayingForm: action.isShowing,
                },
            };
        }

        case 'setSearchAlphaDisplayingIdeas': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    displayingIdeas: action.isShowing,
                },
            };
        }

        case 'setSearchAlphaStrategy': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    idea: action.idea,
                },
            };
        }

        case 'setSearchAlphaUniverse': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    universeId: action.universeId ?? null,
                },
            };
        }

        case 'storeSearchAlphaSplitPercentage': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    splitPercentage: action.splitPercentage,
                },
            };
        }

        default:
            return state;
    }
};
