import { render } from '@thinkalpha/language-services';
import type { KnownAstNode } from '@thinkalpha/language-services';

export const renderArgumentToString = (arg: KnownAstNode | null): string | null => {
    if (!arg) {
        return null;
    }

    return render(arg);
};
