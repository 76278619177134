import type { OutputColumn } from '@thinkalpha/platform-ws-client/contracts/table.js';
import type { ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import type { ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { createTable } from 'src/lib/table';
import { OutputStage, SourceStage } from 'src/lib/tableStages';
import type { Stage } from 'src/lib/tableStages';

const TABLE = 'table';
const ROW_COUNT = 'row-count';
const ALL_TICKERS = 'all-tickers';
const FILTERED = 'filtered';

export const getTotalRowsCountQuery = () => {
    return {
        queryKey: [TABLE, ROW_COUNT, ALL_TICKERS],
        queryFn: async () => {
            const columns: OutputColumn[] = [];
            let stage: Stage = SourceStage.allForKey('ticker');
            stage = OutputStage.fromInputStage(stage, {}, columns);

            return createTable({ leafStage: stage.build() });
        },
        staleTime: 0,
        gcTime: 0,
    };
};

export const getTotalRowsCountForUniverseQuery = (universeId: string) => {
    return {
        queryKey: [TABLE, ROW_COUNT, universeId],
        queryFn: async () => {
            const leafStage = SourceStage.fromTickerUniverseId(universeId).build();
            return createTable({ leafStage });
        },
        staleTime: 0,
        gcTime: 0,
    };
};

/**
 * Currently Screener specific, we will need to handle the possible null from plan.toStage to use with scanner
 */
export const getFilteredRowsCountQuery = (
    plan: ScreenerPlanDTO,
    queryClient: ThinkAlphaQueryClient,
    userViewKey: string,
) => {
    return {
        queryKey: [TABLE, ROW_COUNT, FILTERED, plan.toContract(), userViewKey],
        queryFn: async () => {
            const stage = (await plan.toStage(null, queryClient, false)).build();
            return createTable({ leafStage: stage, userViewKey });
        },
        staleTime: 0,
        gcTime: 0,
    };
};
