import { ConditionModel } from '.';
import type { ConditionStateGuided, ConditionStateNonGuided } from '../../types';
import { ConditionGroupModel } from '../ConditionGroupModel';
import { AstNodeType, parser, render } from '@thinkalpha/language-services';
import type { KnownAstNode } from '@thinkalpha/language-services';
import {
    isComparisonOperator,
    isLogicalOperator,
    removeWrappingParentheses,
} from 'src/features/legacyIfThen/if-then-helpers';
import { v4 } from 'uuid';

const createEmptyGuidedLine = (parent: ConditionGroupModel): ConditionModel => {
    return new ConditionModel({
        id: v4(),
        isSelfEnabled: true,
        parent,
        conditionState: {
            type: 'guided',
            lhs: {
                naturalLanguage: '',
                displayMode: 'formula',
                atom: {
                    status: 'user_code',
                    code: '',
                },
            },
            operator: null,
            rhs: {
                naturalLanguage: '',
                displayMode: 'formula',
                atom: {
                    status: 'user_code',
                    code: '',
                },
            },
        },
    });
};

export const knownAstNodeToCondition = ({
    node,
    parent,
}: {
    node: KnownAstNode;
    parent: ConditionGroupModel;
}): ConditionGroupModel | ConditionModel => {
    // If node is parenthetical, rerun function on content within parentheses
    if (node.type === AstNodeType.paren) {
        return knownAstNodeToCondition({ node: node.content, parent });
    }

    // If node is binary with operator and/or, convert node to a group
    if (node.type === AstNodeType.binaryOperation && isLogicalOperator(node.operator)) {
        const group = new ConditionGroupModel({
            booleanOperator: node.operator,
            children: [],
            id: v4(),
            isSelfEnabled: true,
            isExpanded: true,
            label: '',
            parent,
        });

        const lines: (ConditionGroupModel | ConditionModel)[] = [];
        lines.push(knownAstNodeToCondition({ node: node.lhs, parent: group }));
        if (node.rhs) {
            lines.push(knownAstNodeToCondition({ node: node.rhs, parent: group }));
        }

        for (const line of lines) {
            group.addChild(line);
        }
        group.setIsDirty(false);

        return group;
    }

    // If node is binary with operator and/or, convert node to a line
    if (node.type === AstNodeType.binaryOperation && isComparisonOperator(node.operator)) {
        return new ConditionModel({
            id: v4(),
            conditionState: {
                type: 'guided',
                lhs: {
                    naturalLanguage: '',
                    displayMode: 'formula',
                    atom: {
                        status: 'user_code',
                        code: removeWrappingParentheses(render(node.lhs)),
                    },
                },
                operator: node.operator,
                rhs: {
                    naturalLanguage: '',
                    displayMode: 'formula',
                    atom: {
                        status: 'user_code',
                        code: removeWrappingParentheses(render(node.rhs)),
                    },
                },
            },
            isSelfEnabled: true,
            parent,
        });
    }

    // If node matches none of the criteria, use a default line and lose line content
    return createEmptyGuidedLine(parent);
};

export const conditionStateToGuided = (
    conditionState: ConditionStateNonGuided,
    parent: ConditionGroupModel,
): ConditionGroupModel | ConditionModel => {
    let formula = '';
    if (conditionState.state.atom.status === 'user_code') {
        formula = conditionState.state.atom.code;
    } else if (conditionState.state.atom.status === 'confirmed') {
        formula = conditionState.state.atom.confirmedFormula;
    }

    const { root } = parser(formula, {});
    if (root) {
        return knownAstNodeToCondition({ node: root, parent });
    }

    return createEmptyGuidedLine(parent);
};

export const conditionStateToNonGuided = (conditionState: ConditionStateGuided): ConditionStateNonGuided => {
    const { lhs, operator, rhs } = conditionState;

    const lhsCode = lhs.atom.status === 'user_code' ? lhs.atom.code : '';
    const rhsCode = rhs.atom.status === 'user_code' ? rhs.atom.code : '';

    // Combine the lhs, operator, and rhs into a single formula
    const combinedFormula = `${lhsCode} ${operator ?? ''} ${rhsCode}`.trim();

    // Parse the combined formula
    const { root } = parser(combinedFormula, {});

    if (root) {
        // Use render to convert the AST back to a string
        const formula = render(root);

        return {
            type: 'non-guided',
            state: {
                naturalLanguage: '',
                displayMode: lhs.displayMode === 'formula' && rhs.displayMode === 'formula' ? 'formula' : 'language',
                atom: {
                    status: 'user_code',
                    code: formula,
                },
            },
        };
    }

    // If parsing fails, return a default non-guided state
    return {
        type: 'non-guided',
        state: {
            naturalLanguage: '',
            displayMode: lhs.displayMode === 'formula' && rhs.displayMode === 'formula' ? 'formula' : 'language',
            atom: {
                status: 'user_code',
                code: combinedFormula,
            },
        },
    };
};
