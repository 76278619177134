import type { WatchlistActions } from 'src/store/actions/widgets/watchlist';
import type { WatchlistWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const watchlistReducer = (
    state: WidgetTabViewModel<WatchlistWidgetViewModel>,
    action: WatchlistActions,
): WidgetTabViewModel<WatchlistWidgetViewModel> => {
    switch (action.type) {
        case 'setWatchlistIdea': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    idea: action.idea,
                },
            };
        }

        default:
            return state;
    }
};
