import { analyzer, iterateAst, render } from '@thinkalpha/language-services';
import type { KnownAstNode } from '@thinkalpha/language-services';
import { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import type { IndicatorImportRefModel } from 'src/dtos/IndicatorImportRef';
import { inject, injectable, ReactiveInjectable, reacts } from 'src/features/ioc';
import { getImportsFromRefs, getIndicatorsFromSymbols } from 'src/lib/dictionary/dictionary';
import type { AnalyzerFunction } from 'src/lib/parser';
import { modifyCallNodesByAlias } from 'src/lib/parser/modifyCallNodesByAlias';
import type { ImportsManagerModel } from 'src/models/ImportsManagerModel';
import type { Logger } from 'src/services/Logger';
import type { ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

interface ImportsManagerModelState {
    imports: IndicatorImportModel[];
    analyzers: AnalyzerFunction[];
}

@injectable()
export class ImportsManagerModelImpl extends ReactiveInjectable implements ImportsManagerModel {
    #id: string = v4();

    #implLog: Logger;

    #state: ImportsManagerModelState;

    #autoImportRejectionList = new Set<string>();

    async addImportsFromRefs(refs: IndicatorImportRefModel[]): Promise<void> {
        const resolvedImports = await this.queryClient.fetchUserQuery({
            queryKey: ['importsManager', 'getIndicatorsFromRefs', this.#id, refs],
            queryFn: () => getImportsFromRefs(refs),
        });

        if (!resolvedImports.length) return;

        this.addImports(...resolvedImports.map((resolved) => IndicatorImportModel.fromIndicatorImport(resolved)));
    }

    async addImportsFromRefsAndFormula(refs: IndicatorImportRefModel[], formula: string): Promise<string> {
        const parsedFormula = await this.formulaService.parse(formula);

        if (!parsedFormula.root) {
            this.#implLog.warn('Failed to parse formula');
            return formula;
        }

        const unresolvedImports: IndicatorImportRefModel[] = [];
        for (const ref of refs) {
            // Don't add the import if it's already in the imports list
            if (this.imports.some((i) => i.alias === ref.alias && i.id === ref.id && i.version === ref.version)) {
                continue;
            }
            unresolvedImports.push(ref);
        }

        const resolvedImports = await this.queryClient.fetchUserQuery({
            queryKey: ['importsManager', 'getIndicatorsFromRefs', this.#id, refs],
            queryFn: () => getImportsFromRefs(unresolvedImports),
        });

        let modifiedRoot = parsedFormula.root;
        for (const resolvedImport of resolvedImports) {
            const resolvedImportStartingAlias = resolvedImport.alias;

            let newAlias = resolvedImportStartingAlias;
            let i = 0;
            while (
                this.imports.some(
                    (i) => i.alias === newAlias && (i.id !== resolvedImport.id || i.version !== resolvedImport.version),
                )
            ) {
                i++;
                newAlias = `${resolvedImportStartingAlias}${i}`;
            }
            if (newAlias !== resolvedImportStartingAlias) {
                modifiedRoot = modifyCallNodesByAlias(modifiedRoot, resolvedImport.symbol, resolvedImport.alias);
            }
            this.addImports(IndicatorImportModel.fromIndicatorImport({ ...resolvedImport, alias: newAlias }));
        }

        return render(modifiedRoot);
    }

    constructor(
        @inject('FormulaService') private formulaService: ReactBindings['FormulaService'],
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('Logger') logger: ReactBindings['Logger'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
        this.#implLog = logger.getSubLogger({ name: `ImportsManagerModel:${this.#id}`, id: this.#id });

        this.#state = {
            imports: [],
            analyzers: this.#makeNewAnalyzerFunctions([]),
        };
    }

    get analyzers(): AnalyzerFunction[] {
        return this.#state.analyzers;
    }

    addImports(...indicatorImports: IndicatorImportModel[]): void {
        const newImports = indicatorImports.filter(
            (newImport) =>
                !this.#state.imports.some(
                    (existingImport) =>
                        existingImport.alias === newImport.alias &&
                        existingImport.id === newImport.id &&
                        existingImport.version === newImport.version,
                ),
        );

        this.imports = this.#state.imports.concat(newImports);
    }

    @reacts private set imports(imports: IndicatorImportModel[]) {
        this.#state = {
            imports,
            analyzers: this.#makeNewAnalyzerFunctions(imports),
        };
    }

    get imports(): IndicatorImportModel[] {
        return this.#state.imports;
    }

    async autoImportFromCode(...nodes: KnownAstNode[]): Promise<void> {
        const importRefs: string[] = [];

        for (const rootNode of nodes) {
            for (const node of iterateAst(rootNode)) {
                if (node.type !== 'call') continue;

                const importModel = this.#state.imports.find((imported) => imported.alias === node.source);
                if (importModel) continue;

                if (this.#autoImportRejectionList.has(node.source)) continue;

                importRefs.push(node.source);
            }
        }

        if (!importRefs.length) {
            return;
        }

        const indicatorImports = await this.queryClient.fetchUserQuery({
            queryKey: ['importsManager', 'getIndicatorsFromSymbols', this.#id, importRefs],
            queryFn: () => getIndicatorsFromSymbols(importRefs, 'ticker'),
        });

        const importsWhichCouldNotBeFound = importRefs.filter((ref) => !indicatorImports.some((i) => i.symbol === ref));
        for (const ref of importsWhichCouldNotBeFound) {
            this.#autoImportRejectionList.add(ref);
        }

        this.addImports(
            ...indicatorImports.map((indicatorImport) =>
                IndicatorImportModel.fromIndicatorImport({
                    // TODO: Check for existing imports at this symbol if the alias is already taken
                    alias: indicatorImport.symbol,
                    ...indicatorImport,
                }),
            ),
        );
    }

    clear() {
        if (TRACE) {
            this.#implLog.trace('clear');
        }

        this.imports = [];
    }

    #makeNewAnalyzerFunctions(imports: IndicatorImportModel[]): AnalyzerFunction[] {
        if (TRACE) {
            this.#implLog.trace('makeNewAnalyzerFunctions', { imports });
        }

        const functionDefs = imports.map((ind) => ind.toDictionaryFunctionDef());

        return [(x) => analyzer(x, { functionDefs })];
    }

    removeImport(indicatorImport: IndicatorImportModel): void {
        if (TRACE) {
            this.#implLog.trace('removeImport', { indicatorImport });
        }

        this.imports = this.#state.imports.filter((imported) => imported !== indicatorImport);
    }

    removeUnusedImports(...nodes: KnownAstNode[]): void {
        const seenImports = new WeakSet<IndicatorImportModel>();

        for (const rootNode of nodes) {
            for (const node of iterateAst(rootNode)) {
                if (node.type !== 'call') continue;

                const importModel = this.#state.imports.find((imported) => imported.alias === node.source);
                if (importModel) {
                    seenImports.add(importModel);
                }
            }
        }

        if (TRACE) {
            this.#implLog.trace('removeUnusedImports', { seenImports });
        }

        this.imports = this.#state.imports.filter((imported) => {
            return seenImports.has(imported);
        });
    }

    setImports(imports: IndicatorImportModel[]): void {
        if (TRACE) {
            this.#implLog.trace('setImports', { imports });
        }

        this.imports = imports;
    }
}
