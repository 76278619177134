import type { ScreenerActions } from 'src/store/actions/widgets/screener';
import type { ScreenerWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const screenerReducer = (
    state: WidgetTabViewModel<ScreenerWidgetViewModel>,
    action: ScreenerActions,
): WidgetTabViewModel<ScreenerWidgetViewModel> => {
    switch (action.type) {
        case 'setScreenerDisplayingResults': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    displayingResults: action.isShowing,
                },
            };
        }

        case 'setScreenerDisplayingBuilder': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    displayingForm: action.isShowing,
                },
            };
        }

        case 'storeScreenerSplitPercentage': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    splitPercentage: action.splitPercentage,
                },
            };
        }

        case 'setScreenerIdea': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    idea: action.idea,
                },
            };
        }

        default:
            return state;
    }
};
