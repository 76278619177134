import type { Instant } from '@js-joda/core';
import type { Static } from '@sinclair/typebox';
import { FormatRegistry } from '@sinclair/typebox';
import type { TableStatus } from 'src/components/table-view/model';
import { components } from 'src/features/chatbot/schema/api-schema';

/**
 * There is a good chance this needs to live somewhere else, but for now it's here.
 *
 * Parsing date-time strings is proving painful,
 * for now validating we have a string and saying good enough
 */
FormatRegistry.Set('date-time', (value) => {
    if (typeof value === 'string') {
        return true;
    }
    return false;
});

/**
 * The conversation info type from the API.
 *
 * And ParsedConversationInfo is after our JSON is deserialized from the API,
 * creation_time is no longer a string, but an instance of Instant
 */
type ConversationInfo = Static<typeof components.schemas.ConversationInfo>;
export interface ParsedConversationInfo extends Omit<ConversationInfo, 'creation_time'> {
    creation_time: Instant;
}

export type AlphabotConnectionStatus = TableStatus;

export type ChatConversationResponse = Static<typeof components.schemas.AlphabotConversationMessagesResponse>;

export type ChatConversationDeleteResponse = Static<typeof components.schemas.AlphabotDeleteConversationResponse>;

type ChatConversationHistoryResponse = Static<typeof components.schemas.AlphabotConversationListResponse>;
export interface ParsedChatConversationHistoryResponse
    extends Omit<ChatConversationHistoryResponse, 'conversation_list'> {
    conversation_list: ParsedConversationInfo[];
}

export type AlphabotWebsocketMessageBot = Static<typeof components.schemas.AlphabotWebsocketMessageBot>;
export const AlphabotWebsocketMessageBot = components.schemas.AlphabotWebsocketMessageBot;

export type ConversationMessageDocument = Static<typeof components.schemas.ConversationMessageDocument>;
export const ConversationMessageDocument = components.schemas.ConversationMessageDocument;

export type TextPayload = Static<typeof components.schemas.TextPayload>;
export const TextPayload = components.schemas.TextPayload;

export type ChartPayload = Static<typeof components.schemas.ChartPayload>;
export const ChartPayload = components.schemas.ChartPayload;

export type NlpTablePayload = Static<typeof components.schemas.NlpTablePayload>;
export const NlpTablePayload = components.schemas.NlpTablePayload;

export type NlpSingleValuePayload = Static<typeof components.schemas.NlpSingleValuePayload>;
export const NlpSingleValuePayload = components.schemas.NlpSingleValuePayload;

export type CustomChartTrace = Static<typeof components.schemas.CustomChartTrace>;
export const CustomChartTrace = components.schemas.CustomChartTrace;

export type DefinedChartTrace = Static<typeof components.schemas.DefinedChartTrace>;
export const DefinedChartTrace = components.schemas.DefinedChartTrace;

export type AlphabotExamplesResponse = Static<typeof components.schemas.ExampleQueriesForType>;
export type AlphabotExamplesRawResponse = Static<typeof components.schemas.AlphabotExamplesResponse>;
