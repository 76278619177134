import { UnreachableCaseError } from '../util/unreachableCaseError';
import type {
    CompanySuggestionsResponseData,
    NlpAutocompleteRequestData,
    NlpAutocompleteResponseData,
    NlpExample,
    NlpExampleResponse,
    NlpParseRequestData,
    NlpParseResponseData as NlpParseResponseDataDeprecated,
    NlpSimilarRequestData,
    NlpSimilarResponseData,
} from './contracts';
import { NlpQueryParseType, NlpQueryType } from './contracts';
import type { NlpAPIError } from './error';
import { mapNlpAPIError } from './error';
import { NlpParseResponseDataSchema, NlpParseResponseErrorSchema } from './schema';
import type { NlpParseResponseData, NlpParseResponseError } from './schema';
import type { KnownAstNode } from '@thinkalpha/language-services';
import { AstNodeType } from '@thinkalpha/language-services';
import type { AxiosResponse } from 'axios';
import { memoize } from 'es-toolkit';
import type { Observable, OperatorFunction } from 'rxjs';
import { firstValueFrom, of, pipe, ReplaySubject } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { container } from 'src/StaticContainer';
import { createInstance } from 'src/api';
import type { IndicatorViewModel, IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import { replaceFormulaNodes } from 'src/features/legacyIfThen/if-then-helpers';
import { appConfig } from 'src/lib/config';
import { createImportAlias } from 'src/lib/dictionary/imports-helpers';
import { rxifyAxios } from 'src/lib/http';

const log = container.get('Logger').getSubLogger({ name: 'nlp-parsing' });

const axios = createInstance({
    baseURL: appConfig.nlpApi,
    validateStatus: (status) => (status >= 200 && status <= 299) || status == 422, // 422 Unprocessable Entity is "successful" here
});

const rxAxios = rxifyAxios(axios, mapNlpAPIError());

export function fetchSuggestions(input: string, clientId: string): Observable<NlpParseResponseDataDeprecated> {
    return rxAxios
        .post<NlpParseResponseDataDeprecated | NlpAPIError>('/v1/parse', {
            type: 'query',
            clientId,
            isConditional: true,
            input,
        })
        .pipe(
            map((x) => {
                if (x.status === 422) {
                    return (x.data as NlpAPIError).detail.similar;
                } else {
                    return x.data as NlpParseResponseDataDeprecated;
                }
            }),
        );
}

export function replaceAliasInFormula(formula: string, previousAlias: string, newAlias: string): string {
    return replaceFormulaNodes(formula, [], (node: KnownAstNode | null) => {
        if (node && node.type === AstNodeType.call && node.source === previousAlias) {
            return { ...node, source: newAlias };
        }

        return node;
    });
}

/**
 * A function that processes the aliases and formula recieved from the NLP API
 * with respect to an array of already in-use imports, ensuring that duplicate
 * imports are stripped, duplicate alias names are renamed, and if any
 * adjustments are made to the NLP API aliases, that each alias referenced
 * in the NLP formula is updated.
 *
 * @deprecated Implement in ImportsManagerModel
 *
 * @param input the NlpParseResponseData to process
 * @param existingImports an array of imports from the context where NLP is being used
 *
 * @returns a new NlpParseResponseData with processed formula and aliases properties
 */
export function stripImportDuplicatesFromNlpParseResult(
    input: NlpParseResponseDataDeprecated,
    existingImports: IndicatorImportViewModel[],
): NlpParseResponseDataDeprecated {
    if (!input.result.formula || !input.result.imports) return input;

    const { formula, imports } = input.result.imports.reduce(
        (previous, current) => {
            // If import already exists on the strategy but with a different alias,
            // replace alias in formula with already existing alias
            const importMatch = existingImports.find((previousImport) => {
                return (
                    previousImport.id === current.id &&
                    previousImport.version === current.version &&
                    previousImport.alias !== current.alias
                );
            });

            if (importMatch) {
                return {
                    ...previous,
                    formula: replaceAliasInFormula(previous.formula, current.alias, importMatch.alias),
                };
            }

            // If alias already exists on the strategy but for a different import,
            // create a new alias before adding the new import, and replace the
            // old alias with the new alias in the formula
            const aliasMatch = existingImports.find((previousImport) => {
                return (
                    previousImport.alias === current.alias &&
                    previousImport.id !== current.id &&
                    previousImport.version !== current.version
                );
            });

            if (aliasMatch) {
                // Construct necessary Indicator properties for createImportAlias and cast as Indicator
                const alias = createImportAlias(
                    { symbol: current.alias, key: null } as IndicatorViewModel,
                    existingImports,
                );
                return {
                    formula: replaceAliasInFormula(previous.formula, current.alias, alias),
                    imports: [
                        ...previous.imports,
                        {
                            ...current,
                            alias,
                        },
                    ],
                };
            }

            // Else, no issues exist, so just add the new import
            return { ...previous, imports: [...previous.imports, current] };
        },
        { formula: input.result.formula, imports: [] },
    );

    return { ...input, result: { formula, imports } };
}

/**
 * @deprecated No rxjs
 */
export function getAutocompletePhrase(queryType: NlpQueryType): OperatorFunction<string, string | undefined> {
    return pipe(
        switchMap((input) => {
            if (!input) return of(undefined);

            const request: NlpAutocompleteRequestData = {
                key: 'ticker',
                input,
                isConditional: queryType === 'condition',
            };

            return rxAxios.post<NlpAutocompleteResponseData | NlpAPIError>('/v1/autocomplete', request).pipe(
                map(({ data }: AxiosResponse<NlpAutocompleteResponseData>) => {
                    return data.autocomplete.length ? data.autocomplete[0] : undefined;
                }),
                catchError((error: NlpAPIError | Error) => {
                    log.debug({ message: `NLP /autocomplete encountered an error parsing ${input}. ${error}` });
                    return of(undefined);
                }),
            );
        }),
    );
}

export async function getSimilarQueriesP(req: NlpSimilarRequestData): Promise<NlpSimilarResponseData> {
    const res = await axios.post('/v1/similar', req);

    return res.data;
}

/**
 * @deprecated No rxjs
 */
export function getSimilarQueries(queryType: NlpQueryType): OperatorFunction<string, NlpSimilarResponseData> {
    return pipe(
        switchMap((input) => {
            if (!input) return of({ similar: [], input });

            const key = 'ticker';
            const isConditional = queryType === 'condition';
            const request: NlpSimilarRequestData = { key, input, isConditional };

            return rxAxios.post<NlpSimilarResponseData | NlpAPIError>('/v1/similar', request).pipe(
                map((x) => {
                    const success = x.data as NlpSimilarResponseData;
                    return { ...success, input };
                }),
                catchError((error: NlpAPIError | Error) => {
                    log.debug({ message: `NLP /parse encountered an error parsing ${input}. ${error}` });
                    return of({ similar: [], input });
                }),
            );
        }),
    );
}

export async function parseNaturalLanguageP(
    input: string,
    queryType: NlpQueryType,
    clientId: string,
): Promise<NlpParseResponseData | NlpParseResponseError> {
    const key = 'ticker';
    const type = queryType === 'date' ? NlpQueryParseType.date : NlpQueryParseType.query;
    const isConditional = queryType === 'condition';

    // when all we have is a number, we don't need to make a network call
    if (!isNaN(Number(input))) {
        if (TRACE) {
            log.trace({ name: 'parseNaturalLanguageP', message: 'Input is a number', input });
        }
        return {
            docId: '',
            modified: null,
            key,
            result: { formula: input, imports: [] },
            namedColumns: [],
            benchmarkFormulae: [],
        };
    }

    if (TRACE) {
        log.trace({ name: 'parseNaturalLanguageP', message: 'going to network to parse input', input });
    }

    const request: NlpParseRequestData = { type, key, input, isConditional, clientId, isDone: true };

    const res = await axios.post('/v1/parse', request);

    if (res.status === 422) {
        try {
            const response = NlpParseResponseErrorSchema.parse(res.data);
            return response;
        } catch (e: unknown) {
            if (e instanceof Error) {
                log.error({ message: 'Failed to parse NLP response', error: e });
            }
            throw e;
        }
    } else {
        try {
            const response = NlpParseResponseDataSchema.parse(res.data);
            return response;
        } catch (e: unknown) {
            if (e instanceof Error) {
                log.error({ message: 'Failed to parse NLP response', error: e });
            }
            throw e;
        }
    }
}

/**
 * @deprecated No rxjs
 */
async function parseNaturalLanguage(
    input: string,
    queryType: NlpQueryType,
    clientId: string,
): Promise<NlpParseResponseDataDeprecated> {
    const key = 'ticker';
    const type = queryType === 'date' ? NlpQueryParseType.date : NlpQueryParseType.query;
    const isConditional = queryType === 'condition';

    const genericResponseData: NlpParseResponseDataDeprecated = {
        docId: '',
        similar: [],
        modified: null,
        key,
        clientId,
        result: { formula: '', imports: [] },
        namedColumns: [],
        benchmarkFormulae: [],
    };

    const request: NlpParseRequestData = { type, key, input, isConditional, clientId, isDone: true };

    return await firstValueFrom(
        rxAxios.post<NlpParseResponseDataDeprecated | NlpAPIError>('/v1/parse', request).pipe(
            map((x) => {
                if (x.status === 422) {
                    // couldn't process the user input, so there's instead suggestions
                    const err = x.data as NlpAPIError;
                    return { ...genericResponseData, similar: err.detail.similar };
                } else {
                    const success = x.data as NlpParseResponseDataDeprecated;
                    return { ...success, input };
                }
            }),
            catchError((error: NlpAPIError | Error) => {
                log.debug({ message: `NLP /parse encountered an error parsing ${input}. ${error}` });
                return of({ ...genericResponseData, similar: [] });
            }),
        ),
    );
}

/**
 * @deprecated No rxjs
 */
export const parseNaturalLanguageMemo = memoize(parseNaturalLanguage);

/**
 * @deprecated No rxjs
 */
export const commonNlpExamples$: Observable<NlpExampleResponse> = rxAxios
    .get<NlpExampleResponse>('/v1/examples', { params: { shortlist: true, count: 1000, caseType: 'all_examples' } })
    .pipe(
        map((x) => x.data),
        share({
            connector: () => new ReplaySubject(1),
        }),
    );

export const getNlpExamplesQuery = async (
    type: NlpQueryType,
): Promise<ResourceQueryResponseWithMeta<{ id: string; input: string; formula: string }>> => {
    const { data, count } = (
        await axios.get<NlpExampleResponse>('/v1/examples', {
            params: { shortlist: false, count: 100000, page: 1, caseType: mapTypeToContract(type) },
        })
    ).data;

    return { results: data.map((x) => ({ id: x.input, input: x.input, formula: x.formula })), count };
};

export function mapTypeToContract(type: NlpQueryType): NlpExample['type'] {
    switch (type) {
        case NlpQueryType.operand:
            return 'operands';
        case NlpQueryType.date:
            return 'timeframes';
        case NlpQueryType.condition:
            return 'conditions';
        default:
            throw new UnreachableCaseError(type);
    }
}

export async function getCompanySuggestions(query: string) {
    return (await axios.post<CompanySuggestionsResponseData>('/v1/companies', { input: query })).data;
}
