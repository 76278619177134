import type { EventQueryFn, ChronologicalEventsWidgetModel, EventRow } from './chronological';
import { LocalDate } from '@js-joda/core';
import { computed, signal } from '@preact/signals-react';
import type { ReadonlySignal, Signal } from '@preact/signals-react';
import type { QueryObserverOptions } from '@tanstack/query-core';
import type { ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { TableCreationResultWithColumns } from '@thinkalpha/platform-ws-client/contracts/table.js';
import { from } from 'rxjs';
import type { Event, EventType } from 'src/components/ui/EventList';
import type { IdeaDTO } from 'src/dtos/Idea';
import type { SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { getEvents } from 'src/features/events/api';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { ScannerPlanDTO } from 'src/features/scanner/dtos/ScannerPlanDTO';
import { ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import { WatchlistPlanDTO } from 'src/features/watchlist/dtos/WatchlistPlanDTO';
import { createTable } from 'src/lib/table';
import type { Logger } from 'src/services/Logger';
import { createAlphaLensFromSymbol, createChartFromSymbol } from 'src/store/actions/container';
import {
    userSetEventsEventTypes,
    userSetEventsGoToDate,
    userSetEventsSymbolFilter,
    userSetEventsUniverse,
} from 'src/store/actions/widgets/events';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { EventsWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

// Helper to compare arrays for equality
function arraysEqual<T>(a: T[], b: T[]): boolean {
    if (a === b) return true;
    if (a.length !== b.length) return false;
    return a.every((val, idx) => val === b[idx]);
}

type EventsWidgetModelState =
    | { type: 'initializing' }
    | {
          type: 'initialized';
          changeCloseSlug: Signal<ConcreteIndicator>;
          symbolSlug: Signal<ConcreteIndicator>;
          symbolFilter: Signal<string | null>;
          universeId: Signal<string | null>;
      };

@injectable()
export class ChronologicalEventsWidgetModelImpl extends ReactiveInjectable implements ChronologicalEventsWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('Logger') logger: Logger,
        @inject('DefaultUniverseServiceProvider')
        private readonly defaultUniverseServiceProvider: ReactBindings['DefaultUniverseServiceProvider'],
        @inject('SlugMapServiceProvider')
        private readonly slugMapServiceProvider: ReactBindings['SlugMapServiceProvider'],
        @inject('TableModel') private readonly table: ReactBindings['TableModel'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#state = { type: 'initializing' };

        this.#logger = logger.getSubLogger({ name: 'EventsWidgetModel' });
    }

    get fastIdeas(): ReadonlySignal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]> {
        return this.#fastIdeas as ReadonlySignal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]>;
    }

    #fastIdeas = signal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]>([]);

    get gotoDate() {
        return this.widget.gotoDate;
    }

    async init(tabId: string) {
        this.widgetData.init(tabId);
        this.disposableStack.use(
            from(this.widgetData).subscribe(() => {
                const widget = this.widgetData.widget as EventsWidgetViewModel;
                if (widget.universeId !== this.#userSelectedUniverseId.value) {
                    if (TRACE) {
                        this.#logger.trace({ message: 'User selected new universe', universeId: widget.universeId });
                    }
                    this.#userSelectedUniverseId.value = widget.universeId;
                }

                // Keep eventTypes signal in sync with widget data
                if (widget.eventTypes && !arraysEqual(widget.eventTypes, this.#eventTypes.value)) {
                    if (TRACE) {
                        this.#logger.trace({
                            message: 'Event types changed from redux',
                            eventTypes: widget.eventTypes,
                        });
                    }
                    this.#eventTypes.value = widget.eventTypes;
                }

                // Keep symbolFilter signal in sync with widget data
                if (widget.symbolFilter !== this.#symbolFilterSignal.value) {
                    this.#symbolFilterSignal.value = widget.symbolFilter;
                }
            }),
        );

        // Initialize the event types signal with data from the widget
        this.#eventTypes.value = this.widget.eventTypes || [];

        // Initialize the symbolFilter signal with data from the widget
        this.#symbolFilterSignal.value = this.widget.symbolFilter;

        const defaultUniverseService = await this.defaultUniverseServiceProvider();
        const slugMapService = await this.slugMapServiceProvider();

        const symbolSlug = computed(() => slugMapService.slugMap.value['symbol']);
        const changeCloseSlug = computed(() => slugMapService.slugMap.value['symbol::changeClose']);

        const symbolFilter = signal<string | null>(null);

        const universeId = computed(
            () => this.#userSelectedUniverseId.value ?? defaultUniverseService.universe.value.id,
        );
        const queryOptions: Signal<QueryObserverOptions<TableCreationResultWithColumns>> = computed(() => ({
            queryKey: ['events-widget', 'table', universeId.value],
            queryFn: () => {
                return createTable({
                    leafStage: {
                        type: 'output',
                        columns: [
                            { id: 'symbol', type: 'indicator', indicator: symbolSlug.value },
                            { id: 'changeClose', type: 'indicator', indicator: changeCloseSlug.value },
                        ],
                        inputStage: {
                            type: 'row-source',
                            rowKey: 'ticker',
                            // @ts-expect-error Refactoring this to use universes correctly as part of chronological queries
                            universe: symbolFilter.value
                                ? {
                                      aspects: [],
                                      inclusionList: [symbolFilter.value],
                                      exclusionList: [],
                                      isTemplate: false,
                                  }
                                : (universeId.value ?? undefined),
                        },
                    },
                });
            },
        }));

        this.table.init({
            requestId: v4(),
            initBounds: { firstRow: 0, windowSize: 100000 },
            queryOptions,
        });

        // Use table updates$ directly without snapshots
        // Subscribe to updates$ for real-time changes
        this.disposableStack.use(
            this.table.updates$.subscribe((updates) => {
                if (!updates) return;

                // Process each update batch
                const currentSymbols = new Set(this.#tableSymbols.value);
                let hasChanges = false;

                for (const update of updates) {
                    if ('symbol' in update) {
                        const symbol = update['symbol'] as string;
                        if (symbol && !currentSymbols.has(symbol)) {
                            currentSymbols.add(symbol);
                            hasChanges = true;
                            if (TRACE) {
                                this.#logger.trace('Added symbol from update', { symbol, update });
                            }
                        }
                    }
                }

                // Only update the signal if we have changes
                if (hasChanges) {
                    this.#tableSymbols.value = Array.from(currentSymbols);
                    if (TRACE) {
                        this.#logger.trace('Updated symbols from updates$', { symbols: this.#tableSymbols.value });
                    }
                }
            }),
        );

        this.#state = {
            type: 'initialized',
            changeCloseSlug,
            symbolSlug,
            symbolFilter,
            universeId,
        };
    }

    #logger: Logger;

    onGoToDate(date: LocalDate | null) {
        this.store.dispatch(userSetEventsGoToDate(this.widgetData.tabId, date));
    }

    onSelectEventTypes(eventTypes: EventType[]): void {
        this.store.dispatch(userSetEventsEventTypes(this.widgetData.tabId, eventTypes));
    }

    onSelectSlowIdeas(ideas: (IdeaDTO<ScreenerPlanDTO> | IdeaDTO<WatchlistPlanDTO> | { universeId: string })[]): void {
        // Sync universe ID to redux
        const reduxUniverse = this.#userSelectedUniverseId.value;
        const selectedUniverse = ideas.find((idea) => 'universeId' in idea)?.universeId ?? null;

        if (reduxUniverse !== selectedUniverse) {
            this.store.dispatch(userSetEventsUniverse(this.widgetData.tabId, selectedUniverse));
        }

        // TODO: Other slow ideas
    }

    onSelectFastIdeas(ideas: (IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]): void {
        this.#fastIdeas.value = ideas;
    }

    onSelectSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    openChartForSymbol(symbol: string) {
        this.store.dispatch(
            createChartFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    openLensForSymbol(symbol: string) {
        this.store.dispatch(
            createAlphaLensFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    #resetState() {
        if (this.#state.type === 'initializing') {
            return;
        }

        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, null));
        this.store.dispatch(userSetEventsGoToDate(this.widgetData.tabId, null));

        // Go back to live mode

        // No need to manually update tracked symbols
        // The effect will automatically update tracked symbols based on tableSymbols
        // since symbolFilter was just set to null
    }

    #state: EventsWidgetModelState;

    @reacts private set state(newState: EventsWidgetModelState) {
        this.#state = newState;
    }

    // Use a real signal for the symbolFilter to ensure reactivity
    #symbolFilterSignal = signal<string | null>(null);

    // Expose symbolFilter as a signal
    get symbolFilter(): ReadonlySignal<string | null> {
        return this.#symbolFilterSignal;
    }

    // Method to change the symbol filter
    onChangeSymbolFilter(value: string) {
        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, value === '' ? null : value));
        // No need to manually update tracked symbols
        // The effect will automatically update when symbolFilter changes via this.#trackedSymbols
    }

    // Track symbols from table snapshots
    #tableSymbols = signal<string[]>([]);

    // For external access to table symbols
    get symbols() {
        return this.#tableSymbols as ReadonlySignal<string[]>;
    }

    // Track event types
    #eventTypes = signal<EventType[]>([]);

    get eventTypes() {
        return this.#eventTypes.value;
    }

    // Expose events from chronological query as a signal
    #events = signal<Event[]>([]);

    get events() {
        return this.#events as ReadonlySignal<Event[]>;
    }

    // Define the query function directly without a signal
    #chronologicalQueryFn: EventQueryFn = async (
        symbols: string[] | null,
        prevRow: EventRow | null,
        abortSignal: AbortSignal,
    ): Promise<Iterable<EventRow>> => {
        if (TRACE) {
            this.#logger.trace('Query function called', { symbols, prevRow });
        }
        if (!symbols || symbols.length === 0) return [];

        try {
            // Use the existing getEvents API to fetch event data
            const events = await getEvents({
                symbols: symbols,
                eventTypes: this.#eventTypes.value,
                end: prevRow ? prevRow.timestamp : (this.widget.gotoDate ?? LocalDate.now().plusDays(1)),
                referenceId: prevRow?.id,
                count: 100,
                abortSignal,
            });

            // Transform the events into the EventRow format required by ChronologicalQueryManager
            return events.map((event) => ({
                ...event,
                symbols: [event.symbol], // Map symbol to symbols array
                id: event.eventId, // Map id from eventId
                timestamp: event.exDate, // Use exDate as the timestamp for chronological ordering
            }));
        } catch (error) {
            this.#logger.error('Error fetching events', { error });
            return [];
        }
    };

    get eventsQueryFn(): EventQueryFn {
        return this.#chronologicalQueryFn;
    }

    get universe() {
        return this.widget.universeId;
    }

    set universe(universe: string | null) {
        this.#resetState();
        this.store.dispatch(userSetEventsUniverse(this.widgetData.tabId, universe));

        // When universe changes, wait for the table to update with new symbols
        // The table polling logic will then update the tracked symbols
        // We don't need to do anything here as the timer will pick up the new symbols
    }

    #userSelectedUniverseId: Signal<string | null> = signal(null);

    get widget() {
        return this.widgetData.widget as EventsWidgetViewModel;
    }
}
