import { Instant } from '@js-joda/core';
import type { Duration } from '@js-joda/core';

export const PeriodType = {
    second: 's',
    minute: 'm',
    hour: 'h',
    day: 'd',
    week: 'W',
    month: 'Mo',
    quarter: 'Q',
    year: 'Y',
} as const;
export type PeriodType = (typeof PeriodType)[keyof typeof PeriodType];
const PeriodTypeDict: Record<string, PeriodType> = PeriodType;
export const PeriodNames: readonly string[] = Object.keys(PeriodType);
Object.freeze(PeriodNames);
export const PeriodNameMap: ReadonlyMap<PeriodType, string> = new Map<PeriodType, string>(
    PeriodNames.map((name) => [PeriodTypeDict[name], name]),
);
Object.freeze(PeriodNameMap);
export const PeriodTypes: readonly PeriodType[] = PeriodNames.map((x) => PeriodTypeDict[x] as PeriodType);
Object.freeze(PeriodTypes);
/** 1-based indexing for Mon-Sun (ISO standard), 0-based indexing for Sun-Sat */
export const WeekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
Object.freeze(WeekdayNames);
export const WeekdayNumbersByName: ReadonlyMap<string, number> = new Map<string, number>(
    WeekdayNames.slice(1).map((name, index) => [name.substring(0, 2), index + 1]),
);
Object.freeze(WeekdayNumbersByName);
export type BarLength = SlidingWindow;
export interface SlidingWindow {
    value: number;
    period: PeriodType;
}
export interface Anchor {
    year?: number;
    month?: number;
    day?: number;
    hour?: number;
    minute?: number;
    second?: number;
}
export type RelativePointInTime = null | {
    periods: SlidingWindow[];
    anchor: Anchor | null;
};
export type AbsolutePointInTime = Instant;
export type CountPointInTime = number;
export type PointInTime = AbsolutePointInTime | RelativePointInTime | CountPointInTime;
export interface Timeframe {
    start: PointInTime;
    end?: PointInTime | null;
    barLength?: SlidingWindow;
}
export const HistoryType = {
    realtime: 'realtime',
    lookback: 'lookback',
    static: 'static',
    dynamic: 'dynamic',
    count: 'count',
} as const;
export type HistoryType = (typeof HistoryType)[keyof typeof HistoryType];
export function isPointInTime(x: any): x is PointInTime {
    return isRelativePointInTime(x) || isAbsolutePointInTime(x) || isBarCountPointInTime(x);
}
export function isRelativePointInTime(x: PointInTime | undefined | null | unknown): x is RelativePointInTime {
    if (typeof x !== 'object') {
        return false;
    }
    if (x === null) {
        return true;
    }
    return 'periods' in x;
}
export function isAbsolutePointInTime(x: PointInTime | undefined | null | unknown): x is AbsolutePointInTime {
    return typeof x === 'object' && x instanceof Instant;
}
export function isBarCountPointInTime(x: PointInTime | undefined | null | unknown): x is CountPointInTime {
    return typeof x === 'number';
}
export function isTimeframe(x: any): x is Timeframe {
    if (typeof x !== 'object') {
        return false;
    }
    if (x === null) {
        return true;
    }
    return 'start' in x && isPointInTime(x.start);
}
export function isSlidingWindow(x: any): x is SlidingWindow {
    if (typeof x !== 'object') {
        return false;
    }
    if (x === null) {
        return true;
    }
    return 'value' in x;
}
export function compareSlidingWindow(a: SlidingWindow, b: SlidingWindow): boolean {
    return a.period === b.period && a.value === b.value;
}
export function comparePointsInTime(a: PointInTime | null | undefined, b: PointInTime | null | undefined): boolean {
    if (a === b) {
        return true;
    }
    if (isAbsolutePointInTime(a) && isAbsolutePointInTime(b)) {
        return a.compareTo(b) === 0;
    }
    if (isRelativePointInTime(a) && isRelativePointInTime(b)) {
        if (!a) {
            return false;
        } // b can't be !b also because we checked a === b already
        return a.anchor === b!.anchor && a.periods.every((ap) => b!.periods.some((bp) => compareSlidingWindow(ap, bp)));
    }
    return false;
}
export type SessionType = 'all' | 'market' | 'extended';

export interface AbsoluteInterval {
    start: Instant;
    end: Instant;
}
export interface TailingInterval {
    start: Duration;
    end: null;
}

export type Interval = AbsoluteInterval | TailingInterval;
