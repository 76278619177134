import { effect, Signal, signal } from '@preact/signals-react';
import { QueryObserver } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { OutputColumnWithMetadata } from '@thinkalpha/platform-ws-client/contracts/table.js';
import { stringifyKey, TableClient } from '@thinkalpha/table-client';
import type { Bounds, RowUpdate } from '@thinkalpha/table-client';
import {
    concat,
    connectable,
    distinctUntilChanged,
    filter,
    from,
    map,
    NEVER,
    of,
    ReplaySubject,
    switchMap,
    tap,
} from 'rxjs';
import type { Connectable, Observable } from 'rxjs';
import { getSnapshot } from 'src/api/getTableSnapshot';
import type { TableStatus } from 'src/components/table-view/model';
import { inject, injectable, ReactiveInjectable, reacts } from 'src/features/ioc';
import type { TableCreationOptions, TableModel } from 'src/models/TableModel';
import type { FullKeyType } from 'src/types';
import type { ReactBindings } from 'src/types/bindings';

interface TableState {
    columns: OutputColumnWithMetadata[];
    error: Error | undefined;
    tableCreationResult: FullKeyType;
    tableClient: TableClient | null;
    proxyStatus: TableStatus;
    tableStatus: TableStatus;
    serverUUID: string | null;
}

type NonNullFullKeyType = Exclude<FullKeyType, null>;

type TableStateAction =
    | { type: 'clearTableClient' }
    | { type: 'enterErroredState'; error: Error }
    | { type: 'updateProxyConnectionStatus'; status: TableStatus }
    | { type: 'updateTableConnectionStatus'; status: TableStatus }
    | { type: 'updateServerUUID'; serverUUID: string }
    | {
          type: 'useNewTableCreationResult';
          tableCreationResult: NonNullFullKeyType;
          rawClient: ReactBindings['ProxyClient'];
      };

const DEFAULT_THROTTLE_MS = 1;

const tableStateReducer = (state: TableState, action: TableStateAction): TableState => {
    switch (action.type) {
        case 'clearTableClient':
            return {
                ...state,
                columns: [],
                error: undefined,
                tableClient: null,
                tableCreationResult: null,
                serverUUID: null,
            };
        case 'enterErroredState':
            return {
                ...state,
                columns: [],
                error: action.error,
                tableClient: null,
                tableCreationResult: null,
                serverUUID: null,
            };
        case 'useNewTableCreationResult': {
            const { rawClient, tableCreationResult } = action;
            const tableClient = new TableClient(rawClient, stringifyKey(tableCreationResult.key));
            tableClient.accessCookie = tableCreationResult.tableCookie;
            return {
                ...state,
                columns: tableCreationResult.columns,
                error: undefined,
                tableClient,
                tableCreationResult,
                // Reset server UUID when table changes - we'll get a new one from the observable
                serverUUID: null,
            };
        }
        case 'updateProxyConnectionStatus':
            if (action.status === state.proxyStatus) {
                return state;
            }
            return {
                ...state,
                proxyStatus: action.status,
            };
        case 'updateTableConnectionStatus':
            if (action.status === state.tableStatus) {
                return state;
            }
            return {
                ...state,
                tableStatus: action.status,
            };
        case 'updateServerUUID':
            if (action.serverUUID === state.serverUUID) {
                return state;
            }
            return {
                ...state,
                serverUUID: action.serverUUID,
            };
        default:
            return state;
    }
};

const NEW_TABLE_RESET = Symbol('NEW_TABLE_RESET');
type NEW_TABLE_RESET = typeof NEW_TABLE_RESET;

@injectable()
export class TableModelImpl extends ReactiveInjectable implements TableModel {
    #bounds!: Bounds;

    get bounds() {
        return this.#bounds;
    }

    #columnByIdMap = new Map<string, OutputColumnWithMetadata>();

    get columns() {
        return this.#state.columns;
    }

    #countConnected$: Connectable<number>;
    #count$: Observable<number>;

    get count$() {
        return this.#count$;
    }

    get connectionStatus(): TableStatus {
        const { tableStatus, proxyStatus } = this.#state;

        if (tableStatus === 'uninitialized' || proxyStatus === 'uninitialized') {
            return 'uninitialized';
        }
        if (tableStatus === 'disconnected' || proxyStatus === 'disconnected') {
            return 'disconnected';
        }
        if (tableStatus === 'connecting' || proxyStatus === 'connecting') {
            return 'connecting';
        }
        if (tableStatus === 'connected' && proxyStatus === 'connected') {
            return 'connected';
        }
        return 'uninitialized';
    }

    constructor(
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('ProxyClient') private proxyClient: ReactBindings['ProxyClient'],
        @inject('Logger') logger: ReactBindings['Logger'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#log = logger.getSubLogger({ name: 'table:model' });

        const wasPreviouslyConnected = false;
        this.disposableStack.use(
            this.proxyClient.connectionStatus$.subscribe((status) => {
                this.#log.trace({ message: 'New proxy connection status', status });

                this.#takeStateAction({ type: 'updateProxyConnectionStatus', status });

                if (wasPreviouslyConnected && status === 'disconnected') {
                    this.#log.debug({ message: 'Proxy connection was dropped, refetching TCR' });
                    this.#query?.refetch();
                }
            }),
        );

        // Connect to the TableClient's tsId$ observable manually
        this.disposableStack.use(
            from(this)
                .pipe(
                    map(() => this.tableClient),
                    distinctUntilChanged(),
                    tap((tableClient) => {
                        if (tableClient && tableClient.tsId$ && 'connect' in tableClient.tsId$) {
                            // Assuming tsId$ is a connectable but not connected
                            const connectableObservable = tableClient.tsId$ as any;
                            if (typeof connectableObservable.connect === 'function') {
                                this.disposableStack.use(connectableObservable.connect());
                                if (TRACE) {
                                    this.#log.trace({ message: 'Connected tsId$ observable' });
                                }
                            }
                        }
                    }),
                    switchMap((tableClient) => {
                        if (!tableClient) {
                            if (TRACE) {
                                this.#log.trace({
                                    message: 'No TableClient available for server UUID tracking',
                                });
                            }
                            return NEVER;
                        }

                        if (TRACE) {
                            this.#log.trace({ message: 'Setting up tsId$ subscription for server UUID' });
                        }

                        return tableClient.tsId$;
                    }),
                )
                .subscribe((tsId) => {
                    if (tsId) {
                        if (TRACE) {
                            this.#log.trace({
                                message: 'Received server UUID from tsId$',
                                tsId,
                            });
                        }
                        this.#takeStateAction({ type: 'updateServerUUID', serverUUID: tsId });
                    } else if (TRACE) {
                        this.#log.trace({ message: 'Empty tsId received' });
                    }
                }),
        );

        {
            this.#updateConnected$ = connectable(
                from(this).pipe(
                    map(() => this.tableClient),
                    distinctUntilChanged(),
                    switchMap((tc) => concat(of(NEW_TABLE_RESET), tc ? tc.update$ : NEVER)),
                ),
                { connector: () => new ReplaySubject(1) },
            );

            this.#updatesConnected$ = connectable(
                from(this).pipe(
                    map(() => this.tableClient),
                    distinctUntilChanged(),
                    switchMap((tc) => (tc ? tc.updates$ : NEVER)),
                ),
                { connector: () => new ReplaySubject(1) },
            );

            this.disposableStack.use(this.#updateConnected$.connect());
            this.disposableStack.use(this.#updatesConnected$.connect());

            this.#update$ = this.#updateConnected$.pipe(filter((update) => update !== NEW_TABLE_RESET));
            this.#updates$ = this.#updatesConnected$.pipe(filter((update) => update !== NEW_TABLE_RESET));
        }

        {
            this.#countConnected$ = connectable(
                from(this).pipe(
                    map(() => this.tableClient),
                    distinctUntilChanged(),
                    switchMap((tc) => concat(of(NEW_TABLE_RESET as unknown as number), tc ? tc.rowCount$ : NEVER)),
                ),
                { connector: () => new ReplaySubject(1) },
            );
            this.disposableStack.use(this.#countConnected$.connect());

            this.#count$ = this.#countConnected$.pipe(map((count) => ((count as any) === NEW_TABLE_RESET ? 0 : count)));
        }
    }

    destroy() {
        this.#takeStateAction({ type: 'clearTableClient' });
    }

    get error() {
        return this.#state.error;
    }

    getColumnById(columnId: string): OutputColumnWithMetadata | null {
        return this.#columnByIdMap.get(columnId) ?? null;
    }

    getColumnUpdate$(columnId: string) {
        // this.#log.trace({ message: 'TableClient is not null, returning update$', columnId });
        return this.update$.pipe(map((update) => update[columnId]));
    }

    init(tableCreationOptions: TableCreationOptions) {
        this.#queryOptionsSignal =
            tableCreationOptions.queryOptions instanceof Signal
                ? tableCreationOptions.queryOptions
                : signal(tableCreationOptions.queryOptions);
        this.#query = new QueryObserver(this.queryClient, this.#queryOptionsSignal.value);

        // Arbitrary default value; every location in the product overrides this so it doesn't matter.
        this.#bounds = tableCreationOptions.initBounds || { firstRow: 0, windowSize: 100 };
        this.#throttle = tableCreationOptions.initThrottle;

        this.disposableStack.defer(
            effect(() => {
                this.#query.setOptions(this.#queryOptionsSignal.value);
            }),
        );

        const unsubscribe = this.#query.subscribe((tableCreationResult) => {
            if (tableCreationResult.isPending) {
                this.#takeStateAction({
                    type: 'updateTableConnectionStatus',
                    status: 'connecting',
                });
                return;
            }

            if (!tableCreationResult.isSuccess) {
                this.#log.error({
                    message: 'Table creation failed',
                    error: tableCreationResult.error ?? undefined,
                    tableCreationResult,
                });

                // Put table into error state which the UI can read
                this.#takeStateAction({
                    type: 'enterErroredState',
                    error: tableCreationResult.error ?? new Error('Unknown Table Creation error'),
                });
                this.#takeStateAction({
                    type: 'updateTableConnectionStatus',
                    status: 'disconnected',
                });
                return;
            }

            // How does this get reached? ... for now we'll re-use error state from above
            if (!tableCreationResult.data) {
                this.#log.error({
                    message: 'Table creation resulted in no data',
                    error: tableCreationResult.error ?? undefined,
                });
                this.#takeStateAction({
                    type: 'updateTableConnectionStatus',
                    status: 'disconnected',
                });

                this.#takeStateAction({ type: 'clearTableClient' });
                return;
            }

            if (this.#state.tableClient?.key === stringifyKey(tableCreationResult.data.key)) {
                this.#takeStateAction({ type: 'updateTableConnectionStatus', status: 'connected' });
            }

            if (
                // No client, create a new one
                !this.#state.tableClient ||
                // Recycle the existing client for a new one since the key has changed
                this.#state.tableClient.key !== stringifyKey(tableCreationResult.data.key)
            ) {
                this.#log.trace({ message: 'Creating new TableClient', tableCreationResult: tableCreationResult.data });
                this.#takeStateAction({
                    type: 'useNewTableCreationResult',
                    tableCreationResult: tableCreationResult.data,
                    rawClient: this.proxyClient,
                });

                // TODO: Monitor keys list
                this.#takeStateAction({
                    type: 'updateTableConnectionStatus',
                    status: 'connected',
                });

                const tc = this.#state.tableClient!;

                tc.bounds = this.#bounds;
                tc.throttle = this.#throttle === undefined ? DEFAULT_THROTTLE_MS : this.#throttle;

                return;
            }

            //this.#takeStateAction({ type: 'clearTableClient' });
        });
        this.disposableStack.defer(unsubscribe);

        this.disposableStack.defer(() => {
            if (this.#state.tableClient) {
                this.#state.tableClient[Symbol.dispose]();
            }
        });
    }

    get key() {
        return this.#state.tableClient?.key ?? null;
    }

    get serverUUID() {
        return this.#state.serverUUID;
    }

    #log: ReactBindings['Logger'];

    #query!: QueryObserver<FullKeyType>;

    #queryOptionsSignal!: Signal<UseQueryOptions<FullKeyType>>;

    // TODO: Allow bounds to be set to undefined
    setBounds(bounds: Bounds) {
        this.#bounds = bounds;
        if (this.#state.tableClient) {
            this.#state.tableClient.bounds = bounds;
        }
    }

    setThrottle(throttle: number | undefined, getMoreData: boolean): void {
        this.#throttle = throttle;
        if (this.#state.tableClient) {
            this.#state.tableClient.throttle = throttle === undefined ? DEFAULT_THROTTLE_MS : throttle;

            if (getMoreData) {
                this.#state.tableClient.getMoreData();
            }
        }
    }

    async snapshot({ firstRow, windowSize }: { firstRow?: number; windowSize?: number }) {
        if (this.#state.tableCreationResult === null) {
            return null;
        }

        const { key, tableCookie } = this.#state.tableCreationResult;

        if (!tableCookie) {
            return null;
        }

        return getSnapshot(key, tableCookie, firstRow, windowSize);
    }

    get sortable() {
        if (this.#state.tableCreationResult?.key) {
            return this.#state.tableCreationResult.key.ex !== 'M';
        }
        return false;
    }

    #state: TableState = {
        columns: [],
        error: undefined,
        tableClient: null,
        tableCreationResult: null,
        proxyStatus: 'uninitialized',
        tableStatus: 'uninitialized',
        serverUUID: null,
    };

    private get state() {
        return this.#state;
    }

    @reacts private set state(state: TableState) {
        const previousState = this.#state;
        this.#state = state;

        // Sync the column lookup Map
        if (state.columns.length === 0) {
            this.#columnByIdMap.clear();
        } else if (state.columns !== previousState.columns) {
            this.#columnByIdMap = new Map(state.columns.map((column) => [column.id, column]));
        }

        if (previousState.tableClient !== state.tableClient) {
            if (previousState.tableClient) {
                previousState.tableClient[Symbol.dispose]();
            }
        }
    }

    get tableClient() {
        return this.#state.tableClient;
    }

    #takeStateAction(action: TableStateAction) {
        const nextState = tableStateReducer(this.state, action);
        if (nextState !== this.state) {
            this.state = nextState;
        }
    }

    #throttle: number | undefined;

    get throttle() {
        return this.#throttle;
    }

    #updateConnected$: Connectable<RowUpdate | NEW_TABLE_RESET>;
    #updatesConnected$: Connectable<Iterable<RowUpdate> | NEW_TABLE_RESET>;
    #update$: Observable<RowUpdate>;
    #updates$: Observable<Iterable<RowUpdate>>;

    get update$(): Observable<RowUpdate> {
        return this.#update$;
    }

    get updates$(): Observable<Iterable<RowUpdate>> {
        return this.#updates$;
    }

    updateQueryOptions(queryOptions: UseQueryOptions<FullKeyType>) {
        this.#log.trace({ message: 'Updating query options', queryOptions });
        this.#query?.setOptions(queryOptions);
    }
}
