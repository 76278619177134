import type { AlphaLensWidgetModel } from 'src/contracts/workspace';
import type { AlphaLensActions } from 'src/store/actions/widgets/lens';
import type { WidgetTabViewModel } from 'src/store/types';

export const lensReducer = (
    state: WidgetTabViewModel<AlphaLensWidgetModel>,
    action: AlphaLensActions,
): WidgetTabViewModel<AlphaLensWidgetModel> => {
    switch (action.type) {
        case 'updateAlphaLensSymbol': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbol: action.symbol,
                },
            };
        }

        default:
            return state;
    }
};
