import type { ConditionModel } from '.';
import { IfThenFieldInView } from 'src/contracts/if-then';
import type { Formula, IfThenLine } from 'src/contracts/if-then';
import type { NaturalLanguageAtomState } from 'src/features/nlp/types/naturalLanguageAtomState';

const naturalLanguageAtomStateToFormula = (naturalLanguageAtomState: NaturalLanguageAtomState): Formula => {
    if (naturalLanguageAtomState.atom.status === 'user_code') {
        return {
            fieldInView: IfThenFieldInView.formula,
            formula: naturalLanguageAtomState.atom.code,
            naturalLanguage: naturalLanguageAtomState.naturalLanguage,
        };
    } else if (naturalLanguageAtomState.atom.status === 'confirmed') {
        return {
            fieldInView: IfThenFieldInView.nlp,
            formula: naturalLanguageAtomState.atom.confirmedFormula,
            naturalLanguage: naturalLanguageAtomState.naturalLanguage,
        };
    } else if (naturalLanguageAtomState.atom.status === 'writing') {
        return {
            fieldInView: IfThenFieldInView.nlp,
            naturalLanguage: naturalLanguageAtomState.naturalLanguage,
        };
    }

    return {
        fieldInView: IfThenFieldInView.nlp,
        naturalLanguage: naturalLanguageAtomState.naturalLanguage,
    };
};

export const conditionModelToIfThenLine = (conditionModel: ConditionModel): IfThenLine => {
    const { conditionState } = conditionModel;
    if (conditionState.type === 'guided') {
        return {
            id: conditionModel.id,
            description: null,
            enabled: conditionModel.isSelfEnabled,
            type: 'line',
            mode: 'guided',
            lhs: naturalLanguageAtomStateToFormula(conditionState.lhs),
            operator: conditionState.operator,
            rhs: naturalLanguageAtomStateToFormula(conditionState.rhs),
        };
    }

    return {
        id: conditionModel.id,
        description: null,
        enabled: conditionModel.isSelfEnabled,
        type: 'line',
        mode: 'formula',
        ...naturalLanguageAtomStateToFormula(conditionState.state),
    };
};
