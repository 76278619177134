import { Editor, Text, Element } from '../../slate/helpers';
import type { CustomElement, CustomEditor, CustomText } from '../../slate/types';
import { iterateAst } from '@thinkalpha/language-services';
import type { KnownAstNode, ParserResult } from '@thinkalpha/language-services';

/**
 * This is used to keep track of the AST node that corresponds to a token
 */
export const SLATE_ELEMENT_TO_AST_NODE: WeakMap<CustomElement | CustomText, KnownAstNode> = new WeakMap();

export const mapEditorElementsToAstNodes = (editor: CustomEditor, result: ParserResult | null) => {
    if (!result) {
        for (const [element] of Editor.iterateFilterBlockChildren(editor)) {
            if (Element.cheapIsElement(element) && element.type !== 'whitespace') {
                SLATE_ELEMENT_TO_AST_NODE.delete(element);
            }
        }
        return;
    }

    // Text offset is the number of characters in the document up to the current element
    let textOffset = 0;

    const slateIterator = Editor.iterateFilterBlockChildren(editor);
    let slateNext = slateIterator.next();

    while (!slateNext.done) {
        const [slateElement] = slateNext.value;
        const elementLength = Text.cheapIsText(slateElement)
            ? slateElement.text.length
            : /*
               * This cast is safe because the iterator will only return CustomText or whitespace/tokens, which are
               * guaranteed to have one text child. This is better extra type guards with runtime overhead to
               * satisfy TypeScript.
               */
              (slateElement as unknown as { children: [CustomText] }).children[0].text.length;

        if (!(Element.cheapIsElement(slateElement) && slateElement.type === 'whitespace')) {
            // Create a new AST iterator for each slate element
            const astIterator = iterateAst(result.root);
            let astNext = astIterator.next();

            let mostSpecificMatch: KnownAstNode | null = null;

            while (!astNext.done) {
                const astNode = astNext.value;
                const nodeStart = astNode.ranges.node.start;
                const nodeEnd = astNode.ranges.node.end;

                if (textOffset + elementLength <= nodeStart) {
                    // Move to the next slate element
                    break;
                } else if (textOffset >= nodeEnd) {
                    // Move to the next AST node
                    astNext = astIterator.next();
                } else {
                    // Update the most specific match
                    mostSpecificMatch = astNode;
                    astNext = astIterator.next();
                }
            }

            // Map the current slate element to the most specific AST node match
            if (mostSpecificMatch) {
                SLATE_ELEMENT_TO_AST_NODE.set(slateElement, mostSpecificMatch);
            }
        }

        // Move to the next slate element
        textOffset += elementLength;
        slateNext = slateIterator.next();
    }
};
