import type {
    BespokeIndicatorImport,
    IndicatorImportRef,
} from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type {
    Idea,
    IfThenResearchPlan,
    // IndicatorFormulaPlan,
} from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { IfThenFieldInView } from 'src/contracts/if-then';
import { v4 } from 'uuid';

export async function getSearchAlphaIdeaFromFormula(
    formula: string,
    naturalLanguage: string,
    imports: (BespokeIndicatorImport | IndicatorImportRef)[],
): Promise<(Idea & { plan: IfThenResearchPlan }) | null> {
    const plan: IfThenResearchPlan = {
        type: 'if-then',
        root: {
            type: 'group',
            name: 'root',
            collapsed: false,
            operator: 'and',
            enabled: true,
            id: v4(),
            description: null,
            lines: [
                {
                    type: 'group',
                    name: naturalLanguage,
                    collapsed: false,
                    operator: 'and',
                    enabled: true,
                    id: v4(),
                    description: null,
                    lines: [
                        {
                            type: 'line',
                            formula,
                            naturalLanguage,
                            description: null,
                            id: v4(),
                            mode: 'formula',
                            enabled: true,
                            fieldInView: IfThenFieldInView.nlp,
                        },
                    ],
                },
            ],
        },
        imports,
    };

    const strategy: Idea & { plan: IfThenResearchPlan } = {
        name: naturalLanguage,
        description: null,
        defaultUniverseId: null,
        defaultColumnTemplateId: null,
        isTemplate: false,
        plan,
    };

    return strategy;
}

/*
export async function getStrategyFromFormula(
    formula: string,
    naturalLanguage: string,
    imports: (BespokeIndicatorImport | IndicatorImportRef)[],
): Promise<Idea | null> {
    const plan: IndicatorFormulaPlan = { formula, imports, type: 'formula' };

    const strategy: Idea = {
        name: naturalLanguage,
        description: null,
        defaultUniverseId: null,
        defaultColumnTemplateId: null,
        isTemplate: false,
        plan,
    };

    return strategy;
}
*/
